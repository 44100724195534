import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManageBusinessItems } from "../../../../hooks/useManageBusinessItems";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { getAdsList } from "../../../../features/reducers/ads";
import AdSettings from "./AdSettings";
import AdsAnalytics from "../ManageAnalytics/AdsAnalytics";

export default function ManageAds(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const adsList = useSelector((state) => {
    return state.ads.adsList;
  });

  useEffect(() => {
    dispatch(getAdsList(business.id));
  }, [business.id, dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: business.id,
    isForBusiness: true,
    dispatchAction: getAdsList(business.id),
    itemType: "advertisement",
    deleteItemAPI: "/api/advertisement/delete",
    modalData: {
      title: "Create New Ad",
      fieldLabel: "Ad Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageBusinessItems(hookData);

  return (
    <div>
      <ContentPageTitle title='Advertisement' icon='users' />

      <div className='content-box'>
        <Routes>
          <Route
            path='/'
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an advertisements."
                  btnText='Create New Ad'
                  listItems={adsList}
                />

                <CreateItemButton btnText='Create New Ad' />
              </>
            }
            exact
          />

          <Route path='/:id' element={<AdSettings />} exact />

          <Route path='/:id/insights' element={<AdsAnalytics />} />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
