//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetMessageThreads(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/messages/threadlist/${businessID}/business`,
  });
}

export function requestGetMessageList({ userID, businessID }) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/messages/thread`,
    params: {
      userID,
      businessID,
    },
  });
}
