import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { getMessageThreads } from "../../../../features/reducers/messages";
import { useDispatch, useSelector } from "react-redux";
import LoadingContent from "../../../../common/LoadingContent";
import { Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ThreadItem from "./ThreadItem";
import { Route, Routes } from "react-router-dom";
import ChatBox from "./ChatBox";
import NoThread from "./NoThread";

export default function PrivateMessages(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const messageThreads = useSelector((state) => state.messages.threadsList);
  const loading = useSelector((state) => state.messages.loading);

  useEffect(() => {
    dispatch(getMessageThreads(business.id));
  }, [dispatch, business]);

  return (
    <div>
      <ContentPageTitle title='Messages' icon='users' />

      <div className='content-box'>
        {loading ? (
          <LoadingContent />
        ) : (
          <div className='messages-box-wrapper'>
            <div className='thread-list'>
              <div className='search-box'>
                <Input
                  placeholder='Search Contact'
                  prefix={<FontAwesomeIcon icon={faSearch} />}
                />
              </div>

              {messageThreads.length > 0 ? (
                messageThreads.map((thread) => {
                  return <ThreadItem key={thread.id} thread={thread} />;
                })
              ) : (
                <h2>You currently have no messages</h2>
              )}
            </div>

            <div className='message-box'>
              <Routes>
                <Route path='/' element={<NoThread />} exact />

                <Route
                  path='/:id'
                  element={
                    <ChatBox
                      messageThreads={messageThreads}
                      business={business}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
