//** Import Modules */
import { Button, Form, Input, Alert } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import axiosGeneral from "../../../utils/axioGenetal";

export default function ForgotPasswordForm({ setToggleForm }) {
  const [isSubmitting, setisSubmitting] = useState(false);
  const [response, setResponse] = useState("");

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div id="login-form">
      <Helmet>
        <title>Reset Password - BIZZLL Dashboard</title>
      </Helmet>

      <p>
        Please enter your email address or username. You will receive a link to
        create a new password via email.
      </p>

      <Form
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => {
          setisSubmitting(true);
          const APIURL = "/api/users/requestNewPass";
          axiosGeneral()
            .post(APIURL, { user_login: values.username })
            .then((res) => {
              setResponse(<Alert message={res.data.message} type="success" />);

              setisSubmitting(false);
            })
            .catch((err) => {
              console.log(err.response);
              setResponse(
                <Alert message={err.response.data.message} type="error" />
              );

              setisSubmitting(false);
            });
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username Or Email"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input disabled={isSubmitting} />
        </Form.Item>

        <div className="bottom-buttons">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Reset Password
            </Button>
          </Form.Item>

          <div
            role="button"
            className="link-btn"
            onClick={() => {
              setToggleForm("login");
            }}
          >
            Go back to login form
          </div>
        </div>
      </Form>

      {response !== "" && response}
    </div>
  );
}
