//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setMessageList, setMessageThreads } from "../../reducers/messages";
import {
  requestGetMessageList,
  requestGetMessageThreads,
} from "../requests/messages";

export function* handleGetMessageThreads({ businessID }) {
  try {
    const response = yield call(requestGetMessageThreads, businessID);
    const { data } = response;

    yield put(setMessageThreads(data.messageList));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetMessageList({ userID, businessID }) {
  try {
    const response = yield call(requestGetMessageList, {
      userID,
      businessID,
    });
    const { data } = response;

    yield put(setMessageList(data.messages));
  } catch (err) {
    console.log(err.response);
  }
}
