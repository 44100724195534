//** Import Modules */
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import LoginForm from "./components/LoginForm";

//** Import assets */
import bizzllLogo from "../../images/bizzll-logo-beta.png";
import RegistrationForm from "./components/RegistrationForm";
import { useNavigate } from "react-router";
import { FE_URL } from "../../common/constants";
import ForgotPasswordForm from "./components/ForgotPasswordForm";

//** Separate the components for Layout */
const { Content, Sider } = Layout;

export default function LoginPage(props) {
  const { token } = props;

  const [toggleForm, setToggleForm] = useState("login");

  // Toggle form based on action from URL
  const URLQueryVars = new URL(document.location).searchParams;
  const action = URLQueryVars.get("action");

  useEffect(() => {
    if (action !== undefined && action !== null) {
      setToggleForm(action);
    }
  }, [action]);

  // Redirect to user page if already logged in
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Layout id="login-page">
      <div className="form-content">
        <a href={FE_URL} style={{ textAlign: "center" }} className="logo">
          <img src={bizzllLogo} alt="Bizzll Logo" />
        </a>

        {toggleForm === "login" && <LoginForm setToggleForm={setToggleForm} />}

        {toggleForm === "register" && (
          <RegistrationForm setToggleForm={setToggleForm} />
        )}

        {toggleForm === "forgot" && (
          <ForgotPasswordForm setToggleForm={setToggleForm} />
        )}
      </div>
    </Layout>
  );
}
