//** Define Actions */
export const GET_REVIEWS_LIST = "GET_REVIEWS_LIST";
export const SET_REVIEWS_LIST = "SET_REVIEWS_LIST";

//** Create action functions */
export const getReviewsList = (businessID) => ({
  type: GET_REVIEWS_LIST,
  businessID,
});

export const setReviewsList = (reviewsList) => ({
  type: SET_REVIEWS_LIST,
  reviewsList,
});

//** Create initial state */
const initialState = {
  reviewsList: [],
  loading: true,
};

//** Create the handler function */
const reviewsReducer = (state = initialState, { type, reviewsList }) => {
  switch (type) {
    case SET_REVIEWS_LIST:
      return {
        ...state,
        loading: false,
        reviewsList,
      };
    default:
      return state;
  }
};

export default reviewsReducer;
