import React, { useState, useMemo } from "react";
// import { useState } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Tooltip } from "antd";

export default function HelpButton(props) {
  const { helpText } = props;
  const [openModal, setOpenModal] = useState(false);

  const helpTlt = <span>Click for help</span>;
  const options = ['Show', 'Hide', 'Center'];
  const [arrow, setArrow] = useState('Show');
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

    //* functions to handle modal
    const showModal = () => {
      setOpenModal(true);
    };
  
    const closeModal = () => {
      setOpenModal(false);
    };

  return (
    <div className="help_container">
      <Tooltip placement="bottom" title={helpTlt} arrow={mergedArrow}>
        <FontAwesomeIcon className="helpButton" icon={ faCircleQuestion } onClick={() => showModal()} />
      </Tooltip>
      
      <Modal
        visible={openModal}
        title="Help!"
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Close
          </Button>,
        ]}
      >
        {helpText}
      </Modal>
    </div>
    
  );
}
