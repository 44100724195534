//** Import Modules */
import { call, put } from "redux-saga/effects";
import {
  requestGetBusiness,
  requestGetBusinessList,
  requestGetConnectAccountDetails,
} from "../requests/business";
import { setBusiness, setBusinessList } from "../../reducers/business";

export function* handleGetBusiness({ businessID }) {
  try {
    const businessData = yield call(requestGetBusiness, businessID);
    const connectAccDetails = yield call(
      requestGetConnectAccountDetails,
      businessID
    );

    const business = {
      ...businessData.data,
      connectAcc: connectAccDetails.data,
    };

    yield put(setBusiness(business));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetBusinessList() {
  try {
    const response = yield call(requestGetBusinessList);
    const { data } = response;

    yield put(setBusinessList(data));
  } catch (err) {
    console.log(err.response);
  }
}
