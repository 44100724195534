import { Divider, Table, Space, Modal } from "antd";
import moment from "moment";
import React from "react";
import { DATE_FORMAT } from "../../../../common/constants";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { getGroup } from "../../../../features/reducers/groups";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useDispatch } from "react-redux";
import GroupInviteBtn from "../../components/GroupInviteBtn";

export default function GroupMembers({ group }) {
  // Set the group members list
  const groupMembers = group.groupMembers;

  // Set the functions to block/unblock user
  const dispatch = useDispatch();

  const blockUser = (user) => {
    const APIURL = `/api/group/blockUser`;

    const userId = user.id;

    const payload = {
      userId,
      groupId: group.id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then(() => {
        dispatch(getGroup(group.id));

        Modal.success({
          title: "User Blocked",
          content: `The user ${user.user_fname} ${user.user_lname} has been blocked successfully from your group`,
        });
      })
      .catch((err) => {
        console.log(err.response);

        Modal.error({
          title: "Error blocking user",
          content: err.message,
        });
      });
  };

  const unblockUser = async (user) => {
    const APIURL = `/api/group/unblockUser`;

    const userId = user.id;

    const payload = {
      userId,
      groupId: group.id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then(() => {
        dispatch(getGroup(group.id));

        Modal.success({
          title: "User Un-Blocked",
          content: `The user ${user.user_fname} ${user.user_lname} has been un-blocked successfully from your group`,
        });
      })
      .catch((err) => {
        console.log(err.response);

        Modal.error({
          title: "Error un-blocking user",
          content: err.message,
        });
      });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (user) => {
        return (
          <div className={user.is_blocked ? "is-blocked" : "not-blocked"}>
            {user.user_fname} {user.user_lname}
          </div>
        );
      },
    },
    {
      title: "Username",
      key: "username",
      render: (user) => {
        return <>{user.user_login}</>;
      },
    },
    {
      title: "Join Date",
      key: "join_date",
      render: (user) => {
        return moment(user.date_recorded).format(DATE_FORMAT);
      },
    },
    {
      title: "Action",
      render: (user) => (
        <Space>
          {group.owner_id === user.id ? (
            "Admin"
          ) : user.is_blocked ? (
            <button
              onClick={() => {
                unblockUser(user);
              }}
            >
              Unblock
            </button>
          ) : (
            <button
              onClick={() => {
                blockUser(user);
              }}
            >
              Block
            </button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <ContentPageTitle title='Group Members' icon='users' />

      <Divider />

      <div className='group-invite'>
        <GroupInviteBtn group={group} />
      </div>

      <Table
        dataSource={groupMembers}
        columns={columns}
        className='group-members'
      />
    </>
  );
}
