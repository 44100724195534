//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetGroup(groupID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/group/getGroupById/${groupID}`,
  });
}

export function requestGetGroupsList() {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/group/getUsersGroup`,
  });
}

export function requestGetGroupsMembers(groupID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/group/getGroupMembers/${groupID}`,
  });
}
