import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { getGroupList } from "../../../../features/reducers/groups";
import { useManageBusinessItems } from "../../../../hooks/useManageBusinessItems";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router";
import GroupSettings from "./GroupSettings";
import GroupAnalytics from "../ManageAnalytics/GroupAnalytics";

export default function ManageGroups(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const groupsList = useSelector((state) => state.groups.groupList);

  useEffect(() => {
    dispatch(getGroupList());
  }, [dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: business.id,
    isForBusiness: true,
    itemType: "group",
    dispatchAction: getGroupList(),
    deleteItemAPI: "/api/group",
    modalData: {
      title: "Create New Group",
      fieldLabel: "Group Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageBusinessItems(hookData);

  return (
    <div>
      <ContentPageTitle title='Networking Groups' icon='users' />

      <div className='content-box'>
        <Routes>
          <Route
            path='/'
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have a group listing."
                  btnText='Create New Group'
                  listItems={groupsList}
                />

                <CreateItemButton btnText='Create New Group' />
              </>
            }
            exact
          />

          <Route path='/:id' element={<GroupSettings />} exact />

          <Route path='/:id/insights' element={<GroupAnalytics />} exact />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
