import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getFollowersList } from "../../../../features/reducers/followers";
import LoadingContent from "../../../../common/LoadingContent";
import FollowerItem from "./FollowerItem";

export default function BusinessFollowers(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const followersList = useSelector((state) => state.followers.followersList);
  const loading = useSelector((state) => state.followers.loading);

  useEffect(() => {
    dispatch(getFollowersList(business.id));
  }, [dispatch, business]);

  return (
    <div>
      <ContentPageTitle title='My Followers' icon='users' />

      <div className='content-box'>
        {loading ? (
          <LoadingContent />
        ) : followersList.length > 0 ? (
          <div className='followers-list-wrapper'>
            <div className='followers-list-header'>
              Total number of followers - {followersList.length}
            </div>
            {followersList.map((follower) => {
              return (
                <FollowerItem
                  key={follower.follower_id}
                  followerID={follower.follower_id}
                />
              );
            })}
          </div>
        ) : (
          <h2>You currently have no followers</h2>
        )}
      </div>
    </div>
  );
}
