//* Import Modules */
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

//** Import Assets */
import "./styles/index.scss";

//** Import Components */
import App from "./App";

//** Import Store */
import { store } from "./features/store";

//** Import Web Socket */
import { socket, SocketContext } from "./context/socket";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketContext.Provider value={socket}>
        <Router>
          <App />
        </Router>
      </SocketContext.Provider>
    </Provider>
  </React.StrictMode>
);
