//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setCookie } from "../../../common/manageCookies";
import { setUser } from "../../reducers/user";
import {
  requestGetConnectAccountDetails,
  requestGetUser,
  requestGetUserRole,
} from "../requests/user";

export function* handleGetUser() {
  try {
    const responseGetUser = yield call(requestGetUser);
    const responseGetUserRole = yield call(requestGetUserRole);
    const connectAccDetails = yield call(
      requestGetConnectAccountDetails,
      responseGetUser.data.id
    );

    const user = {
      ...responseGetUser.data,
      role: responseGetUserRole.data,
      connectAcc: connectAccDetails.data,
    };

    yield put(setUser(user));
  } catch (err) {
    console.log(err.response);

    if (err.response.status === 403) {
      setCookie("ubt", "", 0);
      window.location.replace(process.env.REACT_APP_DASHBOARD_URL);
    }
  }
}
