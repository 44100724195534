//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Divider } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import ContentPageTitle from "../../../common/ContentPageTitle";
import SelectDropdown from "../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";
import { getBusiness } from "../../../features/reducers/business";
import TagsField from "../../../common/FormInputs/TagsField";
import LoadingForm from "../../../common/LoadingForm";
import { dashboardGetCategories } from "../data/dashboardGetListData";

export default function BusinessDescription(props) {
  const { business } = props;

  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(business);

  useEffect(() => {
    if (business.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  // Used to get dropdown menu data
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dashboardGetCategories(setCategories);
  }, []);

  return (
    <div>
      <ContentPageTitle title="Business Description" icon="briefcase" />

      <div className="content-box">
        {loadingForm ? (
          <LoadingForm />
        ) : (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={fields}
            onFinishFailed={onFinishFailed}
            onFinish={async (values) => {
              values.name = business.name;

              submitForm(
                values,
                `/api/business/${business.id}`,
                getBusiness(business.id)
              );
            }}
            disabled={isSubmitting}
          >
            <ContentFormSubtitle title="Main Description" />

            <Form.Item
              label="Short Description"
              name="meta_business_slogan"
              tooltip="You can place a short description, such as a slogan or tag line."
            >
              <Input showCount maxLength={120} className="formDescription" />
            </Form.Item>

            <Form.Item
              label="About My Business"
              name="meta_business_description"
            >
              <Input.TextArea
                showCount
                maxLength={2000}
                autoSize={{ minRows: 5, maxRows: 8 }}
                className="formDescription"
              />
            </Form.Item>

            <Divider />

            <ContentFormSubtitle title="Business Category" />

            <Form.Item
              label="Category"
              name="meta_business_category"
              rules={[
                {
                  required: true,
                  message: "Please choose a category!",
                },
              ]}
            >
              <SelectDropdown
                showSearch={true}
                options={
                  categories && categories.length
                    ? categories.map((data) => {
                        return {
                          key: data.name,
                          value: data.name,
                        };
                      })
                    : []
                }
              />
            </Form.Item>

            <Form.Item
              label="Tags"
              name="meta_business_tags"
              tooltip="You can add up to 25 unique tags"
            >
              <TagsField form={form} fieldName="meta_business_tags" />
            </Form.Item>

            <Divider />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
