import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";
import { useStripe } from "@stripe/react-stripe-js";
import { Button, Modal } from "antd";

export default function LinkBankAccount(props) {
  const { objData, handleClick } = props;

  const [submitting, setSubmitting] = useState(false);

  // Initialize stripe
  const stripeJS = useStripe();

  // Form handlers
  const handleBankForm = () => {
    if (handleClick) {
      handleClick();
    }

    setSubmitting(true);

    const APIURL = `/api/stripe/connect/fcSession`;

    const stripeAcc = objData.meta_data.filter(
      (item) => item.meta_key === "stripe_account_id"
    )[0].meta_value;

    const data = {
      stripeAcc,
    };

    axiosWithAuth()
      .post(APIURL, data)
      .then((res) => {
        console.log(res);

        stripeJS
          .collectBankAccountToken({
            clientSecret: res.data.client_secret,
          })
          .then(function (result) {
            console.log("result of bank acc", result);
            if (result.error) {
              // Inform your user that there was an error.
              console.log(result.error.message);
            } else {
              if (result.token) {
                // Create bank account from result.token
                console.log(result);

                const newAPIURL = `/api/stripe/connect/updateBank`;

                const payload = {
                  stripeAcc,
                  token: result.token.id,
                };

                axiosWithAuth()
                  .post(newAPIURL, payload)
                  .then((newRes) => {
                    console.log(newRes);

                    setSubmitting(false);

                    Modal.success({
                      title: "Bank Account Linked Successfully",
                      content: "You have added your bank account successfully",
                      onOk: () => {
                        window.location.reload();
                      },
                      onCancel: () => {
                        window.location.reload();
                      },
                    });
                  })
                  .catch((newErr) => {
                    console.log(newErr.response);

                    setSubmitting(false);

                    Modal.error({
                      title: "Error Linking Bank Account",
                      content: newErr.response.data.message,
                    });
                  });
              }
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button
        type="primary"
        style={{ marginTop: 15 }}
        onClick={handleBankForm}
        loading={submitting}
        disabled={submitting}
      >
        {objData.connectAcc.external_accounts.data.length > 0
          ? "Change Bank Account"
          : "Link Bank Account"}
      </Button>
    </>
  );
}
