//** Import Components */
import { Divider } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function HeaderUserMenu({ user }) {
  const roleCapabilities = user.role ? user.role.capabilities : [];

  return (
    <div className='header-user-menu'>
      <ul>
        <li>
          <a href='/account/'>User Dashboard</a>
        </li>
        <li>
          <a href='/account/my-businesses'>My Businesses</a>
        </li>
        {roleCapabilities.includes("access_affiliate") && (
          <li>
            <a href='/affiliate/'>Affiliate Dashboard</a>
          </li>
        )}
        {roleCapabilities.includes("access_marketer") && (
          <li>
            <a href='/affiliate/'>Marketing Dashboard</a>
          </li>
        )}
        {roleCapabilities.includes("manage_bizzll") && (
          <li>
            <a href='/bizzll-management/'>Manage BIZZLL</a>
          </li>
        )}
      </ul>

      <Divider style={{ margin: 0 }} />

      <ul>
        <li>
          <Link to='/logout'>Logout</Link>
        </li>
      </ul>
    </div>
  );
}
