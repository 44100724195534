import { Table } from "antd";
import React from "react";

export default function GenderGroups() {
  const dataSource = [
    {
      key: "1",
      name: "Male",
      percentage: "0%",
    },
    {
      key: "2",
      name: "Female",
      percentage: "0%",
    },
    {
      key: "3",
      name: "Non-Binary",
      percentage: "0%",
    },
    {
      key: "4",
      name: "Preferred not to say",
      percentage: "0%",
    },
    {
      key: "5",
      name: "Other",
      percentage: "0%",
    },
  ];

  const columns = [
    {
      title: "Genders",
      dataIndex: "name",
      key: "label",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "value",
    },
  ];

  return (
    <div className='demographic-table gender-groups'>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ["none", "none"],
        }}
      />
      ;
    </div>
  );
}
