//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setEvent, setEventsList } from "../../reducers/events";
import {
  requestGetEvent,
  requestGetEventsList,
  requestGetEventsTickets,
} from "../requests/events";

export function* handleGetEvent({ eventID }) {
  try {
    const responseGetEvent = yield call(requestGetEvent, eventID);
    const responseGetEventTickets = yield call(
      requestGetEventsTickets,
      eventID
    );

    const event = {
      ...responseGetEvent.data,
      tickets: responseGetEventTickets.data.map((ticket) => {
        return {
          ...ticket,
          key: ticket.id,
        };
      }),
    };

    yield put(setEvent(event));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetEventsList({ businessID }) {
  try {
    const response = yield call(requestGetEventsList, businessID);
    const { data } = response;

    yield put(setEventsList(data));
  } catch (err) {
    console.log(err.response);
  }
}
