import { Popover, Modal, Button } from "antd";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import axiosWithAuth from "../../utils/axiosWithAuth";
import BankItem from "./BankItem";
import { useStripe } from "@stripe/react-stripe-js";
import { DASHBOARD_URL } from "../constants";

export default function ModifyPayoutSettings(props) {
  const { business, baseURL } = props;

  console.log("business on bank", business);

  // Initialize stripe
  const stripeJS = useStripe();

  // Modal states
  const [bankModal, setBankModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);

  // Form handlers
  const handleBankForm = (values) => {
    const APIURL = "/api/business/create-fc-session";

    const stripeAcc = business.meta_data.filter(
      (item) => item.meta_key === "stripe_account_id"
    )[0].meta_value;

    const data = {
      stripeAcc,
    };

    console.log(data);

    axiosWithAuth()
      .post(APIURL, data)
      .then((res) => {
        console.log(res);

        stripeJS
          .collectBankAccountToken({
            clientSecret: res.data.client_secret,
          })
          .then(function (result) {
            console.log("result of bank acc", result);
            if (result.error) {
              // Inform your user that there was an error.
              console.log(result.error.message);
            } else {
              if (result.token) {
                // Create bank account from result.token
                console.log(result);

                const newAPIURL = `/api/business/update-bank-account`;

                const payload = {
                  stripeAcc,
                  token: result.token.id,
                };

                axiosWithAuth()
                  .post(newAPIURL, payload)
                  .then((newRes) => {
                    console.log(newRes);

                    window.location.reload();
                  })
                  .catch((newErr) => {
                    console.log(newErr.response);
                  });
              }
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //* Function to request edits of company
  const [loadingBTN, setLoadingBTN] = useState(false);

  const editCompany = () => {
    setLoadingBTN(true);

    const APIURL = `/api/stripe/edit/connect`;

    const payload = {
      returnURL: `${DASHBOARD_URL}${baseURL}`,
      stripeAcc: business.connectAcc.id,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log("Edit Payout Account", res);

        window.location.replace(res.data.url);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <Popover
        title='Payout Settings'
        trigger='click'
        content={
          <ul className='payout-menu'>
            <li
              role='button'
              onClick={() => {
                setBankModal(true);
              }}
            >
              Bank Account Settings
            </li>
            <li
              role='button'
              onClick={() => {
                setSettingsModal(true);
              }}
            >
              Payout Settings
            </li>
          </ul>
        }
        placement='bottomRight'
      >
        <button className='payout-popup'>
          <FontAwesomeIcon icon={faGear} />
        </button>
      </Popover>

      <Modal
        visible={bankModal}
        title='Bank Account Details'
        onCancel={() => {
          setBankModal(false);
        }}
        footer={null}
      >
        {business.connectAcc &&
        business.connectAcc.external_accounts.data.length > 0 ? (
          <div className='banks-list'>
            {business.connectAcc.external_accounts.data.map((bank) => {
              return <BankItem bank={bank} key={bank.id} business={business} />;
            })}
          </div>
        ) : (
          <h3>No Bank accounts linked</h3>
        )}

        <Button
          type='primary'
          style={{ marginTop: 15 }}
          onClick={handleBankForm}
        >
          {business.connectAcc &&
          business.connectAcc.external_accounts.data.length > 0
            ? "Change Bank Account"
            : "Link Bank Account"}
        </Button>
      </Modal>

      <Modal
        visible={settingsModal}
        title='Payout Account Settings'
        onCancel={() => {
          setSettingsModal(false);
        }}
        footer={null}
      >
        {/* <h3>Business Name: {business.connectAcc.company}</h3> */}

        <Button
          onClick={editCompany}
          loading={loadingBTN}
          disabled={loadingBTN}
        >
          Edit Payout Information
        </Button>
      </Modal>
    </>
  );
}
