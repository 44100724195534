//** Define Actions */
export const GET_ADS = "GET_ADS";
export const SET_ADS = "SET_ADS";
export const GET_ADS_LIST = "GET_ADS_LIST";
export const SET_ADS_LIST = "SET_ADS_LIST";

//** Create action functions */
export const getAd = (adID) => ({
  type: GET_ADS,
  adID,
});

export const setAd = (ad) => ({
  type: SET_ADS,
  ad,
});

export const getAdsList = (businessID) => ({
  type: GET_ADS_LIST,
  businessID,
});

export const setAdsList = (adsList) => ({
  type: SET_ADS_LIST,
  adsList,
});

//** Create initial state */
const initialState = {
  adsList: [],
  ad: {},
};

//** Create the handler function */
const adsReducer = (state = initialState, { type, ad, adsList }) => {
  switch (type) {
    case SET_ADS:
      return {
        ...state,
        ad,
      };
    case SET_ADS_LIST:
      return {
        ...state,
        adsList: adsList.map((ad) => {
          return {
            ...ad,
            key: ad.id,
          };
        }),
      };
    default:
      return state;
  }
};

export default adsReducer;
