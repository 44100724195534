//** Define Actions */
export const GET_FOLLOWERS_LIST = "GET_FOLLOWERS_LIST";
export const SET_FOLLOWERS_LIST = "SET_FOLLOWERS_LIST";

//** Create action functions */
export const getFollowersList = (businessID) => ({
  type: GET_FOLLOWERS_LIST,
  businessID,
});

export const setFollowersList = (followersList) => ({
  type: SET_FOLLOWERS_LIST,
  followersList,
});

//** Create initial state */
const initialState = {
  reviewsList: [],
  loading: true,
};

//** Create the handler function */
const followersReducer = (state = initialState, { type, followersList }) => {
  switch (type) {
    case SET_FOLLOWERS_LIST:
      return {
        ...state,
        loading: false,
        followersList,
      };
    default:
      return state;
  }
};

export default followersReducer;
