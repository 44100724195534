import React, { useEffect, useState } from "react";
import { Alert } from "antd";
import OrderInfo from "./OrderInfo";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRestaurant } from "../../../../features/reducers/restaurants";
import ListBackBtn from "../../components/ListBackBtn";

export default function OrderSettings(props) {
  //* Get the restaurant Data
  const { id } = useParams();
  const {ordersList} = props;

  console.log('ordersList', props)

  // Initiate action to get restaurant data
  const dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurants.restaurant);

  // useEffect(() => {
  //   dispatch(getRestaurant(id));
  // }, [dispatch, id]);

  return (
    <>
      <ListBackBtn backURL='/business/orders' btnTxt='Back To Orders List' />
      <OrderInfo order={ordersList[0]} />
    </>
  );
}
