//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, Divider, Input } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import { useParams } from "react-router";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../../common/LoadingForm";
import axiosGeneral from "../../../../utils/axioGenetal";
import ListBackButton from "../../../../common/ListBackButton";

export default function CitySettings() {
  // Used to build the form
  const [form] = Form.useForm();

  //* Get the ad Data
  const { id } = useParams();

  // Get city data
  const [city, setCity] = useState();

  console.log("city data", city);

  useEffect(() => {
    const APIURL = `/api/city/single/${id}`;

    console.log("Geting citys");

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        console.log(res);

        setCity(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(city);

  useEffect(() => {
    if (city && city.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <div>
      <ListBackButton
        backURL="/bizzll-management/cities-management"
        btnTxt="Back To Cities List"
      />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={fields}
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            submitForm(values, `/api/city/update/${city.id}`);
          }}
          disabled={isSubmitting}
        >
          <ContentFormSubtitle title="Main Description" />

          <Form.Item
            label="City Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input a city name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="meta_city_thumbnail">
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_city_thumbnail: file,
                })
              }
              maxCount={1}
              label="City Thumbnail Image"
              aspectRatio={1}
              fieldName="meta_city_thumbnail"
              form={form}
            />
          </Form.Item>

          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
