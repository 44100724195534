//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setReviewsList } from "../../reducers/reviews";
import { requestGetReviewsList } from "../requests/reviews";

export function* handleGetReviewsList({ businessID }) {
  try {
    const response = yield call(requestGetReviewsList, businessID);
    const { data } = response;

    yield put(setReviewsList(data));
  } catch (err) {
    console.log(err.response);
  }
}
