//** Import Modules */
import React, { useState } from "react";
import { Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faComment,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function NotificationItem({ notification }) {
  // Create states
  const [notifURL, setNotifURL] = useState("/");
  const [notifIcon, setNotifIcon] = useState(null);
  const [notifText, setNotifText] = useState("");

  useState(() => {
    if (notification.component === "follow") {
      setNotifURL("/business/followers");
      setNotifIcon(faUsers);
      setNotifText("has received a new follower.");
    } else if (notification.component === "comment") {
      setNotifURL(`/business/activity/${notification.item_referenced}`);
      setNotifIcon(faComment);
      setNotifText("has a new comment in an activity post.");
    } else if (notification.component === "like") {
      setNotifURL(`/business/activity/${notification.item_referenced}`);
      setNotifIcon(faThumbsUp);
      setNotifText("has a new like in an activity post.");
    }
  }, []);

  // Set specific notification as read
  const readNotification = () => {
    if (notification.is_read == "0") {
      const APIURL = `/api/users/user/notification/read/${notification.id}`;

      axiosWithAuth()
        .put(APIURL)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <a
      href={notifURL}
      className={`notification-item ${notification.component}`}
      onClick={readNotification}
    >
      <div className="logo">
        <Avatar src={notification.business_logo} size={50} shape="square" />

        <span className="icon">
          <FontAwesomeIcon icon={notifIcon} />
        </span>
      </div>

      <div className="notification-txt">
        <span>{notification.business_name}</span> {notifText}
        <div className="time">
          {moment(notification.date_notified).fromNow()}
        </div>
      </div>

      {notification.is_read == "0" && <span className="not-read"></span>}
    </a>
  );
}
