const dashboardMenu = [
  {
    title: "Main Settings",
    key: "home",
    capability: "manage_business",
    icon: "user-gear",
    items: [],
  },
  {
    title: "Earnings Report",
    key: "manage-earnings",
    capability: "manage_business",
    icon: "file-invoice-dollar",
    items: [],
  },
  {
    title: "My Referrals",
    key: "referrals",
    capability: "manage_business",
    icon: "id-card",
    items: [],
  },
  {
    title: "Market Video",
    key: "market-video",
    capability: "access_marketer",
    icon: "bullhorn",
    items: [],
  },
];

module.exports = {
  dashboardMenu,
};
