import React from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function ListBackButton(props) {
  const { btnTxt, backURL } = props;

  const navigate = useNavigate();

  const goBackTo = () => {
    navigate(backURL);
  };

  return (
    <div className="back-to-list">
      <button onClick={goBackTo}>
        <FontAwesomeIcon icon={faArrowLeft} /> {btnTxt}
      </button>
    </div>
  );
}
