import { Button } from "antd";
import React, { useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function PayoutOnboardingBtn(props) {
  const { businessID, userID, returnURL, stripeAcc, btnTxt, type } = props;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      type='primary'
      loading={isLoading}
      disabled={isLoading}
      onClick={() => {
        setIsLoading(true);
        const APIURL = `/api/stripe/connect/onboarding/${type}`;

        let payload = {
          returnURL,
          typeID: type === "user" ? userID : businessID,
          stripeAcc: stripeAcc ? stripeAcc : undefined,
        };

        console.log(payload);

        axiosWithAuth()
          .post(APIURL, payload)
          .then((res) => {
            if (res.data.url) {
              window.location.replace(res.data.url);
            }
          })
          .catch((err) => {
            setIsLoading(false);

            console.log(err.response);
          });
      }}
    >
      {btnTxt}
    </Button>
  );
}
