//** Import Modules */
import React from "react";
import { Divider, Layout } from "antd";
import HeaderUserBar from "../../common/HeaderUserBar/HeaderUserBar";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";

//** Import Components */
import MainSettings from "./pages/MainSettings/MainSettings";
import ManageEarnings from "./pages/ManageEarnings";
import UserAvatar from "../../common/UserAvatar";
import DashboardMenu from "../../common/DashboardMenu";

//** Import Menu Item */
import { dashboardMenu } from "./data/settingsMenuData";
import ManageReferrals from "./pages/ManageReferrals/ManageReferrals";
import MarketVideo from "./pages/MarketVideo";

//** Separate layout components */
const { Header, Content, Footer } = Layout;

export default function AffiliateDashboard() {
  const baseURL = "/affiliate";

  // Get User Info
  const user = useSelector((state) => state.user.user);
  const roleCapabilities = user.role ? user.role.capabilities : [];

  console.log("user", user);

  return (
    <Layout className='layout affailiate-dashboard'>
      <Header id='user-settings-header'>
        <div className='logo' />

        <HeaderUserBar />
      </Header>
      <Content id='user-settings'>
        <div className='content-header'>
          <UserAvatar user={user} size={100} />

          <div className='user-details'>
            Rick Rod
            <span>
              Status:{" "}
              {roleCapabilities.includes("access_marketer")
                ? "Marketer"
                : "Affiliate"}
            </span>
          </div>
        </div>
        <div className='site-layout-content'>
          <DashboardMenu
            baseURL={baseURL}
            dashboardMenu={dashboardMenu}
            mode='horizontal'
          />

          <Divider />

          <Routes>
            <Route
              path='/'
              element={<MainSettings user={user} baseURL={baseURL} exact />}
            />
            <Route path='/manage-earnings' element={<ManageEarnings />} />
            <Route
              path='/referrals'
              element={<ManageReferrals user={user} />}
            />
            <Route path='/market-video' element={<MarketVideo user={user} />} />
          </Routes>
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Bizzll ©2022
      </Footer>
    </Layout>
  );
}
