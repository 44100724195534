import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  Button,
  message,
  Steps,
  Calendar,
  Alert,
} from "antd";

import moment from "moment";
import ListBackButton from "../../../../src/common/ListBackButton";

export function AppointmentsUserSettings(props) {

  //* Get the appointment Data 
  const { id } = useParams();

  const [business, setBusiness]  = useState('');
  const [appointment, setAppointment] = useState([]);
  const [turns, setTurns] = useState([]);
  const [EnabledDaysCalendar, setEnabledDaysCalendar] = useState([]);
  const [dailyAppointments, setDailyAppointments] = useState([]);

  const [SelectedTurnDay, setSelectedTurnDay] = useState(() =>
    (moment()._d + "").substring(4, 15)
  );
  const [SelectedService, setSelectedService] = useState(null);
  const [SelectedTurn, setSelectedTurn] = useState(null);

  // Get Router info
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const APIEndPoint = `/api/appointments/data/${id}`;
    axiosWithAuth()
      .get(APIEndPoint)
      .then((res) => {
        setAppointment(res.data);
        setBusiness(res.data.business_id);
        setSelectedService(res.data.product);
        disableDays();
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [id]);


  /* GET DAILY APPOINTMENTS LIST */
  const getDailyAppointments = (newValue) => {
    const day = newValue.format('DD');
    const month = newValue.format('MMM');
    const year = newValue.format('YYYY');
    const business_id = business;

    const APIURL = `/api/appointments/list/daily`;

    const data = {
      day,
      month,
      year,
      business_id
    };

    console.log('data', data)

    axiosWithAuth()
      .post(APIURL, data)
      .then((res) => {
        let temp = res.data.map((element) => {
          return moment(element.start_time).format('HH:mm:ss')
        });
        console.log('res.data', res.data)
        setDailyAppointments(temp);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  /* Calendar */
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());

  const onSelect = (newValue) => {
    getDailyAppointments(newValue);
    setValue(newValue);
    // createTurnList(SelectedService, newValue);
  };

  useEffect(() => {
    if(SelectedService != null) {
      createTurnList(SelectedService, value);
    }
  }, [dailyAppointments]);

  const selectTurn = (id) => {
    console.log('id: ', id);
    document
      .querySelectorAll(".single-turn-container_select")
      .forEach((element) => element.classList.remove("selected_turn"));
    document.getElementById(id).classList.add("selected_turn");
    turns.map((turn) => {
      if (turn.TurnId == id) {
        setSelectedTurn(turn);
      }
    });
  };

  const createTurnList = (service, newValue) => {

    if(newValue != undefined) {
      setSelectedTurnDay((newValue._d + "").substring(4, 15));
      setValue(newValue);
      setSelectedValue(newValue);
    }

    var appointmentDuration = service.meta_data.service_duration_time;
    var appointmentDurationHours = Number(appointmentDuration.substr(0, 2));
    var appointmentDurationMinutes = Number(appointmentDuration.substr(3, 2));

    var currentAvailabilityRulesHours = [];
    var currentTurnList = [];

    service.availabilityRules.forEach((availabilityRule) => {
      if (availabilityRule.rule_type === "Range of hours") {
        currentAvailabilityRulesHours.push(availabilityRule);
      }
    });

    var id = 1;

    currentAvailabilityRulesHours.forEach((currentAvailabilityRulesHour) => {
      var startTime = moment(currentAvailabilityRulesHour.rule_val_1, "HH:mm:ss");
      var temporalTime = moment(
        currentAvailabilityRulesHour.rule_val_1,
        "HH:mm:ss"
      );
      temporalTime = temporalTime.add(appointmentDurationMinutes, "minutes");
      temporalTime = temporalTime.add(appointmentDurationHours, "hours");
      var endTime = temporalTime;

      while (
        endTime.isBefore(
          moment(currentAvailabilityRulesHour.rule_val_2, "HH:mm:ss")
        )
      ) {
        var insert = true;

        var turn = {
          TurnId: id,
          TurnDay: SelectedTurnDay,
          availableTurns: service.meta_data.service_max_appointments,
          TurnStartTime:
            startTime._d.getHours() +
            ":" +
            ((startTime._d.getMinutes() + "").length > 1
              ? startTime._d.getMinutes()
              : "0" + startTime._d.getMinutes()),
          TurnEndTime:
            endTime._d.getHours() +
            ":" +
            ((endTime._d.getMinutes() + "").length > 1
              ? endTime._d.getMinutes()
              : "0" + endTime._d.getMinutes()),
        };

        if (
          SelectedService.meta_data.service_min_to_make_appointment_period ==
          "hours"
        ) {
          if (
            moment(
              SelectedTurnDay +
                " " +
                startTime._d.getHours() +
                ":" +
                ((startTime._d.getMinutes() + "").length > 1
                  ? startTime._d.getMinutes()
                  : "0" + startTime._d.getMinutes())
            ).format("MM DD YYYY HH:mm:ss") <
            moment()
              .add(
                SelectedService.meta_data.service_min_to_make_appointment,
                "hours"
              )
              .format("MM DD YYYY HH:mm:ss")
          ) {
            insert = false;
          }
        }

        if (insert) {
          currentTurnList.push(turn);
        }

        startTime.add(appointmentDurationMinutes, "minutes");
        startTime.add(appointmentDurationHours, "hours");
        endTime.add(appointmentDurationMinutes, "minutes");
        endTime.add(appointmentDurationHours, "hours");

        id += 1;
      }

      currentTurnList.forEach((turn, index) => {

        if(dailyAppointments.includes(moment(turn.TurnDay + ' ' + turn.TurnStartTime).format('HH:mm:ss'))) {
          currentTurnList.splice(index, 1);
        }
      });
      
      console.log('daily app', dailyAppointments)
      setTurns(currentTurnList);
    });
  };

  const disableDays = () => {
    var currentAvailabilityRulesDays = [];
    var currentEnableDays = [];
    SelectedService.availabilityRules.forEach((availabilityRule) => {
      if (availabilityRule.rule_type === "Range of days") {
        currentAvailabilityRulesDays = availabilityRule.rule_val_1.split(",");
      }
    });
    currentAvailabilityRulesDays.forEach((currentAvailabilityRulesDay) => {
      if (currentAvailabilityRulesDay == "monday") {
        currentEnableDays.push("Mon");
      }
      if (currentAvailabilityRulesDay == "tuesday") {
        currentEnableDays.push("Tue");
      }
      if (currentAvailabilityRulesDay == "wednesday") {
        currentEnableDays.push("Wed");
      }
      if (currentAvailabilityRulesDay == "thursday") {
        currentEnableDays.push("Thu");
      }
      if (currentAvailabilityRulesDay == "friday") {
        currentEnableDays.push("Fri");
      }
      if (currentAvailabilityRulesDay == "saturday") {
        currentEnableDays.push("Sat");
      }
      if (currentAvailabilityRulesDay == "sunday") {
        currentEnableDays.push("Sun");
      }
    });
    setEnabledDaysCalendar(currentEnableDays);
  };

  const steps = [
    {
      content: (
        <div>
          <div className='appointments_container-calendar'>
            <div className="appointments_container-calendar_calendar">
              <Alert
                message={`You selected date: ${selectedValue?.format(
                  "YYYY-MM-DD"
                )}`}
              />
              <Calendar
                value={value}
                fullscreen={false}
                onSelect={onSelect}
                // onPanelChange={onPanelChange}
                disabledDate={(date) => {
                  if (
                    SelectedService.meta_data
                      .service_min_to_make_appointment_period == "days"
                  ) {
                    if (
                      date <
                      moment().add(
                        24 *
                          SelectedService.meta_data
                            .service_min_to_make_appointment,
                        "hours"
                      )
                    ) {
                      return true;
                    }
                  } else {
                    if (date < moment().subtract(24, "hours")) {
                      return true;
                    } else {
                      if (
                        (date._d + "").includes(EnabledDaysCalendar[0]) ||
                        (date._d + "").includes(EnabledDaysCalendar[1]) ||
                        (date._d + "").includes(EnabledDaysCalendar[2]) ||
                        (date._d + "").includes(EnabledDaysCalendar[3]) ||
                        (date._d + "").includes(EnabledDaysCalendar[4]) ||
                        (date._d + "").includes(EnabledDaysCalendar[5]) ||
                        (date._d + "").includes(EnabledDaysCalendar[6])
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  }
                }}
              />
            </div>
            <div className='appointments_container-calendar_turn'>
              {value && turns.map((turn, index) => {
                return (
                  <div
                    key={turn + "_" + index}
                    className='single-turn-container'
                  >
                    <div
                      className='single-turn-container_select'
                      id={turn.TurnId}
                      onClick={(element) => {
                        selectTurn(element.target.id);
                      }}
                    ></div>
                    <div className='single-turn-container_data'>
                      <div className=''>
                        <label>
                          {moment(turn.TurnStartTime, "HH:mm").format(
                            "hh:mm A"
                          )}
                        </label>
                      </div>
                      <div className=''>
                        <label> {turn.availableTurns} Slot(s) left </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              placeContent: 'end',
              margin: '20px 1% 0 0',
            }}
          >
            <Button
              type='primary'
              style={{
              }}
              onClick={() => {
                if (SelectedTurn != null) {
                  disableDays();

                  // const start_time = moment(SelectedTurn.TurnDay + ' ' + SelectedTurn.TurnStartTime).format("YYYY-MM-DD HH:mm:mm");
                  const start_time = moment((moment(value).format("MMM DD YYYY")) + ' ' + SelectedTurn.TurnStartTime).format("YYYY-MM-DD HH:mm:mm");
                  const status = 'pending';

                  const APIURL = `/api/appointments/update/${id}`;

                  const data = {
                    id,
                    start_time,
                    status
                  };
              
                  axiosWithAuth()
                    .put(APIURL, data)
                    .then((res) => {
                      // navigate('/account/user_appointments');
                      let config = {
                        content: `Appointment updated!`,
                        style: {
                          background: "none",
                        },
                      };
                      message.success(config);
                      window.location.replace("http://localhost:3005/account/user_appointments");
                    })
                    .catch((err) => {
                      console.log(err.response);
                    });

                  console.log('SUBMIT');
                } else {
                  const modal = Modal.error({
                    title: "Error",
                    content: `You must select a turn!`,
                  });

                  setTimeout(() => {
                    modal.destroy();
                  }, 5000);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const [current, setCurrent] = useState(0);

  return (
    <>
      <ListBackButton
        backURL='/account/user_appointments'
        btnTxt='Back To Appointments List'
      />
      {SelectedService !== null && <div>{steps[current].content}</div>}
    </>
  );
}
