import { Switch } from "antd";
import React, { useEffect } from "react";

export default function SwitchSlider(props) {
  const { form, fieldName } = props;

  useEffect(() => {
    const switchVal = form.getFieldValue(fieldName);
    console.log(fieldName, switchVal);

    if (switchVal === 1 || switchVal === "1") {
      form.setFieldsValue({
        [fieldName]: true,
      });
    } else if (switchVal === 0 || switchVal === "0") {
      form.setFieldsValue({
        [fieldName]: false,
      });
    } else if (switchVal === 0 || switchVal === "0") {
      form.setFieldsValue({
        [fieldName]: false,
      });
    }
  }, [fieldName, form]);

  const onChange = (checked) => {
    form.setFieldsValue({
      [fieldName]: checked,
    });
  };

  return <Switch onChange={onChange} />;
}
