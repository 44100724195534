import React from 'react'
import ContentPageTitle from '../../../common/ContentPageTitle'

export default function Home() {
    return (
        <div>
            <ContentPageTitle title='Dashboard Home' icon='users' />
            
            <div className='content-box'>
                <h2>This is home</h2>
            </div>
        </div>
      )
}
