//** Define Actions */
export const GET_BUSINESS = "GET_BUSINESS";
export const SET_BUSINESS = "SET_BUSINESS";
export const GET_BUSINESS_LIST = "GET_BUSINESS_LIST";
export const SET_BUSINESS_LIST = "SET_BUSINESS_LIST";

//** Create action functions */
export const getBusiness = (businessID) => ({
  type: GET_BUSINESS,
  businessID,
});

export const setBusiness = (business) => ({
  type: SET_BUSINESS,
  business,
});

export const getBusinessList = () => ({
  type: GET_BUSINESS_LIST,
});

export const setBusinessList = (businessList) => ({
  type: SET_BUSINESS_LIST,
  businessList,
});

//** Create initial state */
const initialState = {
  businessList: [],
  business: {
    connectAcc: {},
  },
  loadingPage: true,
  isSubmitting: false,
};

//** Create the handler function */
const businessReducer = (
  state = initialState,
  { type, business, businessList }
) => {
  switch (type) {
    case SET_BUSINESS:
      return {
        ...state,
        business,
      };
    case SET_BUSINESS_LIST:
      return {
        ...state,
        businessList,
        loadingPage: false,
      };
    default:
      return state;
  }
};

export default businessReducer;
