//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetFollowersList(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/business/follower/${businessID}`,
  });
}

export function requestFollowerUserData(userID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/users/${userID}`,
  });
}
