//** Import Modules */
import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//** Import Libraries */
library.add(fas);

export default function DashboardMenu(props) {
  const { baseURL, dashboardMenu, mode } = props;

  // Get User Info
  const user = useSelector((state) => state.user.user);
  const roleCapabilities = user.role ? user.role.capabilities : [];

  // Get Router info
  const navigate = useNavigate();
  const location = useLocation();

  // Variables for the accordion type menu
  const rootSubmenuKeys = dashboardMenu.map((menuItem) => {
    return menuItem.key;
  });

  const [openKeys, setOpenKeys] = useState(["manage-earnings"]);

  // Creates accordion effect for menu
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // Checks which tab is open and selects it
  const curLocationSlug = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const curMenuItem = curLocationSlug !== "" ? curLocationSlug : "home";

  useEffect(() => {
    const getCurTabKey = dashboardMenu
      .map((menuItem) => {
        const childSelected = menuItem.items
          .map((subMenuItem) => {
            if (subMenuItem.slug === curLocationSlug) {
              return subMenuItem.parent;
            }

            return "";
          })
          .filter((item) => item !== "")[0];

        if (menuItem.key === curLocationSlug) {
          return menuItem.key;
        } else if (childSelected !== undefined) {
          return childSelected;
        }

        return "";
      })
      .filter((item) => item !== "");

    setOpenKeys(getCurTabKey);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to restrict items depending on capabilities
  const allowAccess = (capability) => {
    if (capability === undefined || roleCapabilities.includes(capability)) {
      return true;
    }

    return false;
  };

  // Build the items for the menu
  const menuItems = dashboardMenu.map((menuItem) => {
    const childrenItems = menuItem.items.map((subMenuItem) => {
      const allowItem = allowAccess(subMenuItem.capability);

      if (!allowItem) {
        return "";
      }

      return {
        label: subMenuItem.label,
        key: subMenuItem.slug,
        icon: <FontAwesomeIcon icon='arrow-right' />,
      };
    });

    const MenuIcon =
      menuItem.icon !== undefined ? (
        <FontAwesomeIcon icon={`fa-solid fa-${menuItem.icon}`} />
      ) : null;

    const allowItem = allowAccess(menuItem.capability);

    if (!allowItem) {
      return "";
    }

    return {
      label: menuItem.title,
      key: menuItem.key,
      children: childrenItems.length > 0 ? childrenItems : null,
      title: menuItem.title,
      icon: MenuIcon,
    };
  });

  //Create click event for menu items
  const menuLink = (slug) => {
    if (slug === "home") {
      navigate(`${baseURL}/`);
    } else {
      navigate(`${baseURL}/${slug}`);
    }
  };

  return (
    <div>
      <Menu
        theme='dark'
        mode={mode}
        defaultSelectedKeys={[curMenuItem]}
        onClick={({ key }) => menuLink(key)}
        items={menuItems}
        className='dashboard-menu'
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      />
    </div>
  );
}
