import { Avatar } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function ThreadItem(props) {
  const { thread } = props;
  console.log(thread);
  // Set a loading state while we fetch the data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (thread.senderInfo) {
      setLoading(false);
    }
  }, [thread.senderInfo]);

  return (
    <NavLink to={`${thread.id}`} className='thread-item' role='button'>
      {loading ? (
        "loading"
      ) : (
        <>
          <div className='user-avatar'>
            <Avatar size={40}>R</Avatar>
          </div>

          <div className='user-info'>
            <div className='meta'>
              <h4>
                {thread.senderInfo.user_fname} {thread.senderInfo.user_lname}
              </h4>
              <div className='relative-time'>
                {moment(thread.latestMessage.date_sent).fromNow()}
              </div>
            </div>

            <div className='recent-message'>{thread.latestMessage.message}</div>
          </div>
        </>
      )}
    </NavLink>
  );
}
