import { Table } from "antd";
import React from "react";

export default function AgeGroups() {
  const dataSource = [
    {
      key: "1",
      name: "15yrs – 20yrs",
      percentage: "0%",
    },
    {
      key: "2",
      name: "21yrs – 30yrs",
      percentage: "0%",
    },
    {
      key: "3",
      name: "31yrs – 40yrs",
      percentage: "0%",
    },
    {
      key: "4",
      name: "41yrs – 50yrs",
      percentage: "0%",
    },
    {
      key: "5",
      name: "51yrs+",
      percentage: "0%",
    },
    {
      key: "6",
      name: "Other",
      percentage: "0%",
    },
  ];

  const columns = [
    {
      title: "Age Groups",
      dataIndex: "name",
      key: "label",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "value",
    },
  ];

  return (
    <div className='demographic-table age-groups'>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ["none", "none"],
        }}
      />
      ;
    </div>
  );
}
