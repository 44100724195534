//** Import Modules */
import React, { useEffect, useState } from "react";
import { DASHBOARD_URL } from "../../../../common/constants";

//** Import Components */
import ContentPageTitle from "../../../../common/ContentPageTitle";
import PayoutOnboardingBtn from "../../../../common/StripePayout/PayoutOnboardingBtn";
import PayoutTableDetails from "../../../../common/StripePayout/PayoutTableDetails";

export default function PayoutSettings(props) {
  const { business, baseURL } = props;

  const [hasAcc, setHasAcc] = useState(false);

  const payoutBaseURL = `${baseURL}/payout-settings`;

  useEffect(() => {
    if (Object.keys(business.connectAcc).length !== 0) {
      setHasAcc(true);
    }
  }, [business]);

  return (
    <div>
      <ContentPageTitle title='Payout Settings' icon='money-bill-transfer' />

      <div className='content-box'>
        {hasAcc ? (
          <PayoutTableDetails
            connectAcc={business.connectAcc}
            objData={business}
            type='business'
            baseURL={payoutBaseURL}
          />
        ) : (
          <>
            <h3>Seems like you don't have a payout account.</h3>

            <PayoutOnboardingBtn
              businessID={business.id}
              type='business'
              btnTxt='Create Payout Account'
              returnURL={`${DASHBOARD_URL}${payoutBaseURL}`}
            />
          </>
        )}
      </div>
    </div>
  );
}
