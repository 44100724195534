//** Define Actions */
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

//** Create action functions */
export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
});

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  notifications,
});

//** Create initial state */
const initialState = {
  notifications: [],
};

//** Create the handler function */
const notificationsReducer = (
  state = initialState,
  { type, notifications }
) => {
  switch (type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
