import { Avatar, Rate } from "antd";
import moment from "moment";
import React from "react";

export default function ReviewItem(props) {
  const { review } = props;

  const reviewer = review.userInfo;
  const datePosted = moment(new Date(review.created)).format("MMM DD, YYYY");

  return (
    <div className='review-item'>
      <div className='review-avatar'>
        <Avatar size={40}>{reviewer.user_fname.charAt(0)}</Avatar>
      </div>

      <div className='review-content'>
        <h4>
          {reviewer.user_fname} {reviewer.user_lname}
        </h4>

        <div className='meta'>
          <div className='review-score'>
            <Rate defaultValue={review.rating} disabled={true} />
          </div>

          <div className='date'>{datePosted}</div>
        </div>

        <p>{review.text}</p>
      </div>
    </div>
  );
}
