//** Import Modules */
import React, { useEffect, useState } from "react";
import { Button, Form, Input, Divider } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import ContentPageTitle from "../../../common/ContentPageTitle";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";
import { getBusiness } from "../../../features/reducers/business";
import BusinessHours from "../../../common/FormInputs/BusinessHours";
import UploadMedia from "../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../common/LoadingForm";

export default function BusinessProfile(props) {
  const { business } = props;

  const [profileURL, setProfileURL] = useState("");

  useEffect(() => {
    if (business.meta_data && business.meta_data.length > 0) {
      const getProfileHandle = business.meta_data.filter(
        (b) => b.meta_key === "business_handle"
      )[0];

      if (getProfileHandle !== undefined) {
        const profileURL = `https://bizzll.com/business/${getProfileHandle.meta_value}`;

        setProfileURL(profileURL);
      }
    }
  }, [business]);

  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(business);

  useEffect(() => {
    if (business.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  return (
    <div>
      <ContentPageTitle title='Business Profile Settings' icon='briefcase' />

      <div className='content-box'>
        {profileURL && (
          <a
            href={profileURL}
            className='preview-btn'
            target='_blank'
            rel='noreferrer'
          >
            View Profile
          </a>
        )}

        {loadingForm ? (
          <LoadingForm />
        ) : (
          <Form
            form={form}
            layout='vertical'
            autoComplete='off'
            initialValues={fields}
            onFinishFailed={onFinishFailed}
            onFinish={async (values) => {
              values.name = business.name;

              submitForm(
                values,
                `/api/business/${business.id}`,
                getBusiness(business.id)
              );
            }}
            disabled={isSubmitting}
          >
            <ContentFormSubtitle title='Profile Information' />

            <Form.Item name='meta_business_cover'>
              <UploadMedia
                setMedia={(file) =>
                  form.setFieldsValue({
                    meta_business_cover: file,
                  })
                }
                maxCount={1}
                label='Cover Image'
                description='1920px * 384px Image - Max Size 10MB'
                aspectRatio={5}
                form={form}
                fieldName='meta_business_cover'
              />
            </Form.Item>

            <div className='form-row'>
              <Form.Item
                label='Profile Handle'
                name='meta_business_handle'
                rules={[
                  {
                    required: true,
                    message: "Please input your business name!",
                  },
                ]}
                tooltip='This is your profile handle. It will be used as your URL for your profile.'
              >
                <Input />
              </Form.Item>

              <Form.Item label='Website' name='meta_business_website'>
                <Input />
              </Form.Item>
            </div>

            <Divider />

            <ContentFormSubtitle
              title='Business Hours'
              subtitle='This will be used to help determine which businesses are open, giving priority to open hours on the directory'
            />

            <BusinessHours
              label='Sunday'
              checkboxFieldName='meta_is_available_sunday'
              openTimeFieldName='meta_open_time_sunday'
              closeTimeFieldName='meta_close_time_sunday'
              form={form}
            />

            <BusinessHours
              label='Monday'
              checkboxFieldName='meta_is_available_monday'
              openTimeFieldName='meta_open_time_monday'
              closeTimeFieldName='meta_close_time_monday'
              form={form}
            />

            <BusinessHours
              label='Tuesday'
              checkboxFieldName='meta_is_available_tuesday'
              openTimeFieldName='meta_open_time_tuesday'
              closeTimeFieldName='meta_close_time_tuesday'
              form={form}
            />

            <BusinessHours
              label='Wednesday'
              checkboxFieldName='meta_is_available_wednesday'
              openTimeFieldName='meta_open_time_wednesday'
              closeTimeFieldName='meta_close_time_wednesday'
              form={form}
            />

            <BusinessHours
              label='Thursday'
              checkboxFieldName='meta_is_available_thursday'
              openTimeFieldName='meta_open_time_thursday'
              closeTimeFieldName='meta_close_time_thursday'
              form={form}
            />

            <BusinessHours
              label='Friday'
              checkboxFieldName='meta_is_available_friday'
              openTimeFieldName='meta_open_time_friday'
              closeTimeFieldName='meta_close_time_friday'
              form={form}
            />

            <BusinessHours
              label='Saturday'
              checkboxFieldName='meta_is_available_saturday'
              openTimeFieldName='meta_open_time_saturday'
              closeTimeFieldName='meta_close_time_saturday'
              form={form}
            />

            <Divider />

            <Form.Item>
              <Button type='primary' htmlType='submit' loading={isSubmitting}>
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
