//** Import Modules */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Input, Modal } from "antd";

//** Import Components */
import ContentPageTitle from "../../../common/ContentPageTitle";
import axiosWithAuth from "../../../utils/axiosWithAuth";

export default function ClaimBusiness() {
  // Set state variables
  const [isloading, setIsloading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [business, setBusiness] = useState({});

  // Get the business ID
  const search = useLocation().search;
  const businessId = new URLSearchParams(search).get("businessId");

  // Get the business data of the business you are going to claim
  useEffect(() => {
    const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/business/${businessId}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        console.log(res);
        setBusiness(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [businessId]);

  const [form] = Form.useForm();

  const handlesubmit = async (values) => {
    setIsloading(true);

    const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/business/claimBusiness`;
    const verificationData = {
      data: businessId,
      verificationcode: values.verification_code,
    };

    console.log(verificationData);

    await axiosWithAuth()
      .post(APIURL, verificationData)
      .then((res) => {
        console.log(res);

        setIsloading(false);

        Modal.success({
          title: "Claimed Successfully",
          content:
            "Thank you for claiming the business business-name. You now have the ability to manage it",
          onOk: () => {
            window.location.replace("/business");
          },
        });
      })
      .catch((err) => {
        console.log(err.response);

        setIsloading(false);

        Modal.error({
          title: "Error Claiming Business",
          content: err.response.data.message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleResend = async () => {
    setIsReset(true);
    // const APIURL = `/api/business/claimbusinessVerification`;
    // await axiosGeneral()
    //   .post(APIURL, { emailtype: "business", businessId: businessId })
    //   .then((res) => {
    //     console.log(res);
    //     setTimeout(() => {
    //       if (res && res.status == 200) {
    //         message.success(`Email sent Successfully`);
    //         setIsReset(false);
    //       }
    //     }, 1500);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setTimeout(() => {
    //       message.error({
    //         content: err?.response?.data?.message,
    //         className: "ant-message",
    //       });
    //       setIsReset(false);
    //     }, 1500);
    //   });
  };

  return (
    <div>
      <ContentPageTitle title='Claim Business' icon='briefcase' />

      <div className='content-box'>
        {businessId === null ||
        businessId === "" ||
        business.id === undefined ? (
          "Invalid Token"
        ) : (
          <>
            <h3>
              You are about to claim the business "{business.name}". Please
              input the code that was sent to your email on the field below. In
              the case the code has expired, you may request a new code below.
              If all fails, please contact BIZZLL for support.
            </h3>

            <Form
              name='basic'
              form={form}
              autoComplete='off'
              layout='vertical'
              disabled={isloading}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={(values) => {
                handlesubmit(values);
              }}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label='Enter Verification Code'
                name='verification_code'
                tooltip='Please enter the 6-digit code that was sent to the email.'
                rules={[
                  {
                    required: true,
                    message: "Please enter six digit verification code!",
                  },
                ]}
              >
                <Input maxLength={6} />
              </Form.Item>

              <div className='verify-buttons'>
                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={isloading}>
                    Submit
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type='primary'
                    loading={isReset}
                    disabled={isReset}
                    onClick={handleResend}
                  >
                    {isReset ? "Resending" : "Resend Code"}
                  </Button>
                </Form.Item>
              </div>
            </Form>

            <div className='supportEmail-link'>Contact Bizzll Support</div>
          </>
        )}
      </div>
    </div>
  );
}
