//** Import Modules */
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

//** Import Libraries */
library.add(fas);

export default function ContentPageTitle(props) {
  const { icon, title } = props;

  return (
    <div>
      <h3 className='page-heading'>
        <span>
          <FontAwesomeIcon icon={`fa-solid fa-${icon}`} />
        </span>
        {title}
      </h3>
    </div>
  );
}
