import React from "react";
import { Avatar } from "antd";
import moment from "moment";

export default function MessageItem(props) {
  const { message, senderInfo } = props;

  console.log("message", message, senderInfo);

  return (
    <div
      className={
        senderInfo.id === message.sender_id
          ? "message-wrapper"
          : "message-wrapper recipient"
      }
    >
      <div className='user-avatar'>
        <Avatar size={40}>R</Avatar>
      </div>

      <div className='message'>{message.message}</div>

      <div className='date-sent'>
        {moment(new Date(message.date_sent)).format("MM/DD/YYYY, hh:mm A")}
      </div>
    </div>
  );
}
