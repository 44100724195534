import React from "react";
import { Collapse } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingColumns } from "@fortawesome/free-solid-svg-icons";

const { Panel } = Collapse;

export default function BankItem({ bank, business }) {
  return (
    <div className='bank-item'>
      <Collapse>
        <Panel
          header={
            <div className='bank-header'>
              <div className='icon'>
                <FontAwesomeIcon icon={faBuildingColumns} />
              </div>
              <div className='bank-info'>
                {bank.bank_name}
                <span>****{bank.last4}</span>
              </div>
            </div>
          }
          key='1'
        >
          <ul>
            <li>
              <strong>Routing Number:</strong> {bank.routing_number}
            </li>
            <li>
              <strong>Country:</strong> {bank.country}
            </li>
            <li>
              <strong>Currency:</strong> {bank.currency}
            </li>
          </ul>
        </Panel>
      </Collapse>
    </div>
  );
}
