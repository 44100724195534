//** Import Modules */
import React, { useEffect, useState } from "react";
import { Tag, Input, Button } from "antd";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function TagsField(props) {
  const { form, fieldName } = props;

  const [inputVisible, setInputVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsIDs, setTagsIDs] = useState([]);

  // Handle input field
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    let newTags = [...tags];
    let newTagIDs = [...tagsIDs];

    try {
      const APIURL = `/api/business/createTag`;

      const tagData = {
        term_name: inputValue,
        term_slug: inputValue.toLowerCase().replace(/ /g, "_"),
        term_type: "business",
      };

      const newTag = await axiosWithAuth().post(APIURL, tagData);

      if (newTags.indexOf(newTag.data.term_name) === -1) {
        newTags = [...newTags, newTag.data.term_name];
        newTagIDs = [...newTagIDs, newTag.data.id.toString()];
      }

      setTags(newTags);
      setTagsIDs(newTagIDs);
      setInputValue("");
      setInputVisible(false);

      form.setFieldsValue({
        [fieldName]: newTagIDs.join(),
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  // Get the tags
  useEffect(() => {
    if (form.getFieldValue(fieldName) && form.getFieldValue(fieldName).length > 0) {
      const tagIDs = form.getFieldValue(fieldName).split(",");

      getTagList(tagIDs);
    }
  }, [fieldName, form]);

  const getTagList = async (tagIDs) => {
    let tagList = [];

    try {
      if (tagIDs && tagIDs.length > 0) {
        tagList = await Promise.all(
          tagIDs.map(async (tag) => {
            const response = await axiosWithAuth().get(
              `${process.env.REACT_APP_BE_API_URL}/api/business/getTags/id/${tag}`
            );

            return response.data.term_name;
          })
        );
      }
    } catch (err) {
      console.log(err.response);
    }

    setTagsIDs(tagIDs);
    setTags(tagList);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleClose = async (removedTag) => {
    let newTags = [...tags];
    let newTagIDs = [...tagsIDs];

    try {
      newTags = tags.filter((tag) => tag !== removedTag);

      newTagIDs = await Promise.all(
        newTags.map(async (tag) => {
          const response = await axiosWithAuth().get(
            `${process.env.REACT_APP_BE_API_URL}/api/business/getTags/name/${tag}`
          );

          return response.data.id.toString();
        })
      );
    } catch (err) {
      console.log(err.response);
    }

    console.log(newTags, newTagIDs);

    setTags(newTags);
    setTagsIDs(newTagIDs);
    form.setFieldsValue({
      [fieldName]: newTagIDs.join(),
    });
  };

  return (
    <>
      <div className='tags-list'>
        {tags.map((tag, index) => {
          const sanitizedName = tag.toLowerCase().replace(/ /g, "_");

          return (
            <Tag
              key={`${sanitizedName}-${index}`}
              closable
              onClose={(e) => {
                e.preventDefault();
                handleClose(tag);
              }}
            >
              {tag}
            </Tag>
          );
        })}

        {!inputVisible && tags.length < 25 && (
          <Button onClick={showInput} className='site-tag-plus'>
            Add Tag
          </Button>
        )}
      </div>

      {inputVisible && (
        <>
          <Input
            type='text'
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleInputConfirm();
              }
            }}
          />

          <Button
            onClick={handleInputConfirm}
            style={{
              marginTop: "4px",
            }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
}
