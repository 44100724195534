//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setService, setServicesList } from "../../reducers/services";
import {
  requestGetService,
  requestGetServicesList,
  requestGetServicesAvailabilityRules,
} from "../requests/services";

export function* handleGetService({ serviceID }) {
  try {
    const responseGetService = yield call(requestGetService, serviceID);
    const responseGetServicesAvailabilityRules = yield call(
      requestGetServicesAvailabilityRules,
      serviceID
    );
    
    const service = {
      ...responseGetService.data,
      availabilityRules: responseGetServicesAvailabilityRules.data.map((AvailabilityRule) => {
        return {
          ...AvailabilityRule,
          //key: AvailabilityRule.id,
        };
      }),
    };

    yield put(setService(service));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetServicesList({ businessID }) {
  try {
    const response = yield call(requestGetServicesList, businessID);
    const { data } = response;

    yield put(setServicesList(data));
  } catch (err) {
    console.log(err.response);
  }
}
