//** Import Modules */
import React, { useEffect, useState } from "react";

import { Checkbox, Form, TimePicker } from "antd";

export default function BusinessHours(props) {
  const {
    checkboxFieldName,
    openTimeFieldName,
    closeTimeFieldName,
    label,
    form,
  } = props;

  const [isAvailable, setAvailable] = useState(false);

  const onChange = (e) => {
    setAvailable(e.target.checked);
  };

  useEffect(() => {
    const checkboxVal = form.getFieldValue(checkboxFieldName);

    if (checkboxVal === 1 || checkboxVal === "1") {
      setAvailable(true);
      form.setFieldsValue({
        [checkboxFieldName]: true,
      });
    } else if (checkboxVal === 0 || checkboxVal === "0") {
      setAvailable(false);
      form.setFieldsValue({
        [checkboxFieldName]: false,
      });
    }
  }, [checkboxFieldName, form]);

  const hourFormat = "hh:mm A";

  return (
    <div className="business-hours-field">
      <div className="checkbox-field">
        <Form.Item name={checkboxFieldName} valuePropName="checked">
          <Checkbox onChange={onChange}>{label}</Checkbox>
        </Form.Item>
      </div>

      {isAvailable && (
        <>
          <Form.Item
            name={openTimeFieldName}
            rules={[
              {
                required: true,
                message: "This Field is Required!",
              },
            ]}
          >
            <TimePicker format={hourFormat} />
          </Form.Item>

          <Form.Item
            name={closeTimeFieldName}
            rules={[
              {
                required: true,
                message: "This Field is Required!",
              },
            ]}
          >
            <TimePicker format={hourFormat} />
          </Form.Item>
        </>
      )}
    </div>
  );
}
