//** Define Actions */
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";

//** Create action functions */
export const getUser = () => ({
  type: GET_USER,
});

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

//** Create initial state */
const initialState = {
  user: {
    meta_data: [],
    user_email: "",
    user_fname: "",
    user_lname: "",
    user_login: "",
    user_registered: "",
    connectAcc: {},
  },
};

//** Create the handler function */
const userReducer = (state = initialState, { type, user }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user,
      };
    default:
      return state;
  }
};

export default userReducer;
