//** Import Modules */
import React, { useState } from "react";
import { Button } from "antd";
import EventTicketForm from "./EventTicketForm";

export default function AddEventTicketBtn(props) {
  const { event } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Modal handlers
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type='primary' onClick={showModal}>
        Add Ticket
      </Button>

      <EventTicketForm
        event={event}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        editedData={{}}
      />
    </>
  );
}
