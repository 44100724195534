//** Import Modules */
import React from "react";
import { Table, Space, Modal } from "antd";
import EditEventTicketBtn from "../../components/EditEventTicketBtn";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useDispatch } from "react-redux";
import { getEvent } from "../../../../features/reducers/events";
import EventAttendeeList from "../../components/EventAttendeeList";
import moment from "moment";

export default function EventTicketsList(props) {
  const { event } = props;

  const tickets = event.tickets;

  const dispatch = useDispatch();

  const deleteTicket = (ticketID) => {
    const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/delete/${ticketID}`;

    if (window.confirm("Are you sure you wish to delete this item?")) {
      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          console.log(res);

          const modal = Modal.success({
            title: "Ticket Deleted",
            content: `Ticket was deleted successfully!`,
          });

          setTimeout(() => {
            modal.destroy();
          }, 5000);

          dispatch(getEvent(event.id));
        })
        .catch((err) => {
          console.log(err.response);

          const modal = Modal.error({
            title: "Error Deleting Ticket",
            content: err.message,
          });

          setTimeout(() => {
            modal.destroy();
          }, 5000);
        });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => {
        return moment(date).format("MM/DD/YYYY");
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => {
        return moment(date).format("MM/DD/YYYY");
      },
    },
    {
      title: "Action",
      render: (text) => (
        <Space>
          <button
            onClick={() => {
              deleteTicket(text.id);
            }}
            className='dashboardDeleteBtn'
          >
            Delete
          </button>

          <EditEventTicketBtn event={event} editedData={text} />

          <EventAttendeeList ticket={text} />
        </Space>
      ),
    },
  ];

  return (
    <div className='event-tickets-list'>
      <Table dataSource={tickets} columns={columns} />
    </div>
  );
}
