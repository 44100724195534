const dashboardMenu = [
  {
    title: "Home",
    key: "home",
    capability: "manage_bizzll",
    icon: "house-chimney",
    items: [],
  },
  {
    title: "Cities",
    key: "cities-management",
    capability: "manage_bizzll",
    icon: "city",
    items: [],
  },
  {
    title: "My Events",
    key: "main-5",
    capability: "access_networking_plus",
    icon: "calendar-days",
    items: [
      {
        label: "Manage",
        slug: "events",
        capability: "access_networking_plus",
        parent: "main-5",
      },
      {
        label: "Zoom Settings",
        slug: "zoom-settings",
        capability: "access_networking_plus",
        parent: "main-5",
      },
    ],
  },
  {
    title: "Logout",
    key: "logout",
    capability: "manage_business",
    icon: "right-from-bracket",
    items: [],
  },
];

module.exports = {
  dashboardMenu,
};
