//** Define Actions */
export const GET_MESSAGE_THREADS = "GET_MESSAGE_THREADS";
export const SET_MESSAGE_THREADS = "SET_MESSAGE_THREADS";
export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST";
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST";

//** Create action functions */
export const getMessageThreads = (businessID) => ({
  type: GET_MESSAGE_THREADS,
  businessID,
});

export const setMessageThreads = (threadsList) => ({
  type: SET_MESSAGE_THREADS,
  threadsList,
});

export const getMessageList = (businessID, userID) => ({
  type: GET_MESSAGE_LIST,
  businessID,
  userID,
});

export const setMessageList = (messageList) => ({
  type: SET_MESSAGE_LIST,
  messageList,
});

//** Create initial state */
const initialState = {
  threadsList: [],
  messageList: [],
  loading: true,
};

//** Create the handler function */
const messagesReducer = (
  state = initialState,
  { type, threadsList, messageList }
) => {
  switch (type) {
    case SET_MESSAGE_LIST:
      return {
        ...state,
        messageList,
      };
    case SET_MESSAGE_THREADS:
      return {
        ...state,
        loading: false,
        threadsList,
      };
    default:
      return state;
  }
};

export default messagesReducer;
