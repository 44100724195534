//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setGroup, setGroupList } from "../../reducers/groups";
import {
  requestGetGroup,
  requestGetGroupsList,
  requestGetGroupsMembers,
} from "../requests/groups";

export function* handleGetGroups({ groupID }) {
  try {
    const responseGetGroup = yield call(requestGetGroup, groupID);
    const responseGetGroupMembers = yield call(
      requestGetGroupsMembers,
      groupID
    );

    const group = {
      ...responseGetGroup.data,
      groupMembers: responseGetGroupMembers.data.map((member) => {
        return {
          ...member,
          key: member.id,
        };
      }),
    };

    yield put(setGroup(group));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetGroupsList() {
  try {
    const response = yield call(requestGetGroupsList);
    const { data } = response;

    yield put(setGroupList(data));
  } catch (err) {
    console.log(err.response);
  }
}
