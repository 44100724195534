import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { DASHBOARD_URL } from "../../common/constants";
import { setCookie } from "../../common/manageCookies";

export default function LogoutPage() {
  const URLQueryVars = new URL(document.location).searchParams;
  const redirectVar = URLQueryVars.get("redirect");
  const redirect = redirectVar ? redirectVar : DASHBOARD_URL;

  console.log(redirect, DASHBOARD_URL);

  useEffect(() => {
    setCookie("ubt", "", 0);

    setTimeout(() => {
      window.location.replace(redirect);
    }, 1000);
  }, [redirect]);

  return (
    <div className="message-page">
      <Helmet>
        <title>Logging Out - BIZZLL Dashboard</title>
      </Helmet>

      <div className="message-box">You are being logged out</div>
    </div>
  );
}
