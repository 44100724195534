//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetRestaurant(restaurantID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/products/${restaurantID}`,
  });
}

export function requestGetRestaurantsList(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/products/manage/restaurant/business/${businessID}`,
  });
}
