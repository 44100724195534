//** Import Components */
import { useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Form, Input, Modal, Button, message, Table, Empty, Space } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";

export const useManageBusinessItems = ({
  itemType,
  ownerID,
  modalData,
  dispatchAction,
  deleteItemAPI,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* Function to create new item
  const createListItem = (values) => {
    const APIURL = `/api/${itemType}/create`;

    const listingData = {
      name: values.name,
      owner_id: ownerID,
    };

    axiosWithAuth()
      .post(APIURL, listingData)
      .then((res) => {
        console.log(res.data);

        let config = {
          content: `New ${itemType} created!`,
          style: {
            background: "none",
          },
        };
        message.success(config);

        closeModal();
        setIsSubmitting(false);
        dispatch(dispatchAction);
        modalForm.resetFields();
      })
      .catch((err) => {
        console.log(err.response);
        message.error(err.message);
        setIsSubmitting(false);
      });
  };

  //* Function to delete a list item
  const deleteListItem = (APIURL) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          dispatch(dispatchAction);
          console.log(res.data, APIURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    setIsSubmitting(true);

    modalForm.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsSubmitting(false);
  };

  //* Component that holds the modal
  const modalComponent = (
    <Modal
      visible={openModal}
      title={modalData.title}
      onCancel={closeModal}
      footer={[
        <Button key='back' onClick={closeModal}>
          Close
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isSubmitting}
          onClick={submitModalForm}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={modalForm}
        layout='vertical'
        name='userForm'
        onFinish={createListItem}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name='name'
          label={modalData.fieldLabel}
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );

  const CreateItemButton = ({ btnText }) => {
    return (
      <div className='create-item'>
        <Button type='primary' onClick={showModal}>
          {btnText}
        </Button>
      </div>
    );
  };

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <Link to={`${text.id}`}>{text.name}</Link>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Last Modified",
      key: "modified",
      render: (text) => (
        <>{moment(new Date(text && text.modified)).format(dateFormat)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size='middle'>
          <button
            onClick={() => {
              const APIURL = `${deleteItemAPI}/${text.id}`;
              deleteListItem(APIURL, text.id);
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              navigate(`${text.id}`);
            }}
          >
            Manage
          </button>
          <button
            onClick={() => {
              navigate(`${text.id}/insights`);
            }}
          >
            Analytics
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [modalComponent, CreateItemButton, ListComponent];
};
