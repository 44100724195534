//** Import Modules */
import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useDispatch, useSelector } from "react-redux";
import { getReviewsList } from "../../../../features/reducers/reviews";
import ReviewItem from "./ReviewItem";
import LoadingForm from "../../../../common/LoadingForm";

export default function ManageReviews(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const reviewsList = useSelector((state) => state.reviews.reviewsList);
  const loadingReviews = useSelector((state) => state.reviews.loading);

  useEffect(() => {
    dispatch(getReviewsList(business.id));
  }, [dispatch, business]);

  // Get the average score
  const [averageScore, setAverageScore] = useState(0);

  useEffect(() => {
    if (reviewsList.length > 0) {
      let averageRating = 0;

      reviewsList.forEach((data) => {
        averageRating = averageRating + data.rating;
      });

      let finalAvgRating = averageRating / reviewsList.length;
      setAverageScore(finalAvgRating);
    }
  }, [reviewsList]);

  return (
    <div>
      <ContentPageTitle title='User Reviews' icon='users' />

      <div className='content-box'>
        {loadingReviews ? (
          <LoadingForm />
        ) : reviewsList.length > 0 ? (
          <div className='reviews-list-wrapper'>
            <div className='reviews-list-header'>
              <div className='total-count'>
                Total number of reviews - {reviewsList.length}
              </div>

              <div className='average-scored'>
                Overall average rating - {averageScore}
              </div>
            </div>

            <div className='reviews-list'>
              {reviewsList.map((review) => {
                return <ReviewItem review={review} key={review.id} />;
              })}
            </div>
          </div>
        ) : (
          <h2>You currently have no reviews</h2>
        )}
      </div>
    </div>
  );
}
