import React from "react";
import { Helmet } from "react-helmet";

export default function BillingHistory() {
  return (
    <div>
      <Helmet>
        <title>Billing History - BIZZLL Dashboard</title>
      </Helmet>

      <h2>No Purchases Made</h2>
    </div>
  );
}
