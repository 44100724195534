import { Button, DatePicker, Divider, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { DATE_FORMAT } from "../../../common/constants";
import UploadMedia from "../../../common/FormInputs/UploadMedia";

export default function MarketVideo() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Used to build the form
  const [form] = Form.useForm();

  // Used to handle sending notification to admin
  const sendAdminNotif = (videoData) => {
    const APIURL = "/api/advertisement/market/video";

    let payload = {
      videoData,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        setIsSubmitting(false);

        const modal = Modal.success({
          title: "Email Sent!",
          content: `Email has been sent successfully`,
        });

        setTimeout(() => {
          modal.destroy();
        }, 5000);

        form.resetFields();
      })
      .catch((err) => {
        console.log(err.response);
        setIsSubmitting(false);

        Modal.error({
          title: "Error Sending Email.",
          content: err.message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <ContentFormSubtitle
        title='Market a Video'
        subtitle='This form is meant to market a video that will be sent to a BIZZLL admin.'
      />

      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        defaultValue
        onFinishFailed={onFinishFailed}
        onFinish={async (values) => {
          setIsSubmitting(true);
          console.log(values);
          sendAdminNotif(values);
        }}
        disabled={isSubmitting}
      >
        <Form.Item
          name='meta_event_image'
          rules={[
            {
              required: true,
              message: "Please upload an image!",
            },
          ]}
        >
          <UploadMedia
            setMedia={(file) =>
              form.setFieldsValue({
                meta_event_image: file,
              })
            }
            maxCount={1}
            label='Event Featured Image'
            form={form}
            fieldName='meta_event_image'
            description='1080px * 1080px Image - Max Size 2MB'
          />
        </Form.Item>
        <Form.Item
          label='Campaign Title'
          name='title'
          tooltip='A Campaign/Title field to describe the video that we are uploading:  Example: "A common food ingredient that is killing slowly millions of people".'
          rules={[
            {
              required: true,
              message: "Please input a campaign title!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Video URL'
          name='video_url'
          tooltip='A link of the video from the BIZZLL video page that is to be promoted.'
          rules={[
            {
              required: true,
              message: "Please input a Video URL!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Campaign Description'
          name='campaign_description'
          tooltip='Enter a short description of what this campaign is about.'
        >
          <Input.TextArea
            showCount
            maxLength={1000}
            autoSize={{ minRows: 5, maxRows: 8 }}
            className='formDescription'
          />
        </Form.Item>

        <Divider />

        <ContentFormSubtitle
          title="Company's Information"
          subtitle='Information of the company referred on the video'
        />

        <Form.Item label='Business Profile URL' name='business_profile_url'>
          <Input />
        </Form.Item>

        <Form.Item label='Company Name' name='company_name'>
          <Input />
        </Form.Item>

        <Form.Item label='Contact Name' name='contact_name'>
          <Input />
        </Form.Item>

        <Form.Item label='Contact Email' name='contact_email'>
          <Input />
        </Form.Item>

        <Divider />

        <ContentFormSubtitle title='Date Settings' />

        <div className='form-row'>
          <Form.Item
            label='Start Date'
            name='start_date'
            rules={[
              {
                required: true,
                message: "Please input your event date!",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>

          <Form.Item
            label='End Date'
            name='end_date'
            rules={[
              {
                required: true,
                message: "Please input your event date!",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} />
          </Form.Item>
        </div>

        <Divider />

        <ContentFormSubtitle
          title='Social Media Campaign Settings'
          subtitle='BIZZLL will use the information below in order to help promote the event across social media'
        />

        <Form.Item
          label='Facebook Campaign'
          name='facebook_campaign'
          rules={[
            {
              required: true,
              message: "Please add a Facebook campaign post!",
            },
          ]}
          tooltip='Facebook recommend posts should be somewhere between 40 and 50 characters. Max 2000 chars.'
        >
          <Input.TextArea
            showCount
            maxLength={2000}
            autoSize={{ minRows: 5, maxRows: 8 }}
            className='formDescription'
            placeholder='Facebook recommend posts should be somewhere between 40 and 50 characters. Max 2000 chars.'
          />
        </Form.Item>

        <Form.Item
          label='Twitter Campaign'
          name='twitter_campaign'
          tooltip='Twitter recommends post should be between 71 and 100 characters. Max 280 characters.'
          rules={[
            {
              required: true,
              message: "Please add a Twitter campaign post!",
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={280}
            autoSize={{ minRows: 5, maxRows: 8 }}
            className='formDescription'
            placeholder='Twitter recommends post should be between 71 and 100 characters. Max 280 characters.'
          />
        </Form.Item>

        <Form.Item
          label='Instagram Campaign'
          name='instagram_campaign'
          tooltip='Captions can be up to 2000 characters long, and can contain up to 30 hashtags.'
          rules={[
            {
              required: true,
              message: "Please add an Instagram campaign post!",
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={2000}
            autoSize={{ minRows: 5, maxRows: 8 }}
            className='formDescription'
            placeholder='Captions can be up to 2000 characters long, and can contain up to 30 hashtags.'
          />
        </Form.Item>

        <Form.Item
          label='Linkedin Campaign'
          name='linkedin_campaign'
          tooltip='Linkedin recommends post should be 140 characters. Max 1300 characters.'
          rules={[
            {
              required: true,
              message: "Please add a Linkedin campaign post!",
            },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={1300}
            autoSize={{ minRows: 5, maxRows: 8 }}
            className='formDescription'
            placeholder='Linkedin recommends post should be 140 characters. Max 1300 characters.'
          />
        </Form.Item>

        <Divider />

        <h3>Please ensure that the info above is correct and accurate.</h3>

        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isSubmitting}>
            {isSubmitting ? "Sending" : "Submit Form"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
