import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { DASHBOARD_URL } from "../../common/constants";
import { setCookie } from "../../common/manageCookies";
import axiosGeneral from "../../utils/axioGenetal";

export default function ReferralHandler() {
  const { code } = useParams();

  const [exists, setExists] = useState("");

  // Let's check if the referral code exists
  useEffect(() => {
    const APIURL = `/api/users/verifyReferral/${code}`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        if (res.data.meta_value !== undefined) {
          setCookie("referral", res.data.meta_value, 2);
          setExists("yes");

          window.location.replace(
            `${DASHBOARD_URL}/?action=register&code=${code}`
          );
        } else {
          setExists("no");
        }

        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // setCookie("ubt", tokenInfo.token, 2);
  }, [code]);

  return (
    <div className="message-page">
      <Helmet>
        <title>BIZZLL Referral</title>
      </Helmet>

      <div className="message-box">
        {exists === "yes" && "Redirecting..."}
        {exists === "no" && "The referral URL code is invalid."}
      </div>
    </div>
  );
}
