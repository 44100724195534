//** Import Modules */
import { Route, Routes } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUser } from "./features/reducers/user";
import { getCookie } from "./common/manageCookies";
import { Helmet } from "react-helmet";

//** Import Components */
import BusinessDashboard from "./routes/BusinessDashboard/BusinessDashboard";
import PageNotFound from "./common/PageNotFound";
import LoginPage from "./routes/Login/LoginPage";
import PrivateRoute from "./common/PrivateRoute";
import UserSettings from "./routes/UserDashboard/UserSettings";
import AffiliateDashboard from "./routes/AffiliateDashboard/AffiliateDashboard";
import LogoutPage from "./routes/Login/LogoutPage";
import RestrictedContent from "./common/RestrictedContent";
import BizzllManagement from "./routes/BizzllManagement/BizzllManagement";
import ResetPasswordPage from "./routes/Login/ResetPasswordPage";
import ReferralHandler from "./routes/Login/ReferralHandler";
import NotificationManager from "./common/ManageNotifications";

function App() {
  // Initiate action to get user's business list
  const dispatch = useDispatch();

  // Logic for checking if have an auth token
  const getToken = getCookie("ubt");

  useEffect(() => {
    if (getToken) {
      dispatch(getUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="App">
      <Helmet>
        <title>BIZZLL Dashboard</title>
      </Helmet>

      <Routes>
        {/* Main login/reg form page */}
        <Route path="/" element={<LoginPage token={getToken} />} exact />

        {/* Password reset page */}
        <Route path="/password-reset/:token" element={<ResetPasswordPage />} />

        {/* Dashboard for Business Dashboard */}
        <Route
          path="/business/*"
          element={
            <PrivateRoute>
              <BusinessDashboard />
            </PrivateRoute>
          }
        />

        {/* Dashboard for user settings */}
        <Route
          path="/account/*"
          element={
            <PrivateRoute>
              <UserSettings />
            </PrivateRoute>
          }
        />

        {/* Dashboard for affiliate settings */}
        <Route
          path="/affiliate/*"
          element={
            <PrivateRoute>
              <AffiliateDashboard />
            </PrivateRoute>
          }
        />

        {/* Dashboard for Bizzll Management */}
        <Route
          path="/bizzll-management/*"
          element={
            <PrivateRoute>
              <BizzllManagement />
            </PrivateRoute>
          }
        />

        {/* Logout Component */}
        <Route path="/logout/*" element={<LogoutPage />} />

        {/* Referral Code Handler */}
        <Route path="/ref/:code" element={<ReferralHandler />} />

        {/* Error Pages */}
        <Route path="/restricted-page" element={<RestrictedContent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      <NotificationManager />
    </div>
  );
}

export default App;
