import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { Avatar, Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faFlag } from "@fortawesome/free-solid-svg-icons";
import ReportModal from "../../../../common/ReportModal";
import { useSelector } from "react-redux";

//** Declare Meta component from antd Card */
const { Meta } = Card;

export default function FollowerItem(props) {
  const { followerID } = props;

  // Get User Info
  const user = useSelector((state) => state.user.user);

  const [follower, setFollower] = useState({});
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const APIURL = `/api/users/${followerID}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        setFollower(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [followerID]);

  const openReportModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <Card
          style={{
            width: 300,
            marginTop: 16,
          }}
          actions={[
            <FontAwesomeIcon icon={faEnvelope} key='message' />,
            <FontAwesomeIcon
              icon={faFlag}
              key='report'
              onClick={openReportModal}
            />,
          ]}
        >
          <Meta
            avatar={<Avatar>{follower.user_fname.charAt(0)}</Avatar>}
            title={`${follower.user_fname} ${follower.user_lname}`}
          />
        </Card>
      )}

      <ReportModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        accusedId={follower.id}
        user={user}
        typeId={follower.id}
        type={"User"}
        option={[
          { key: "Inappropriate behavior", value: "Inappropriate behavior" },
          {
            key: "Impersonating Other User",
            value: "Impersonating Other User",
          },
          {
            key: "Violence",
            value: "Violence",
          },
          {
            key: "Harassment",
            value: "Harassment",
          },
        ]}
      />
    </>
  );
}
