import React, { useEffect, useState } from "react";
import { Alert } from "antd";
import RestaurantInfo from "./RestaurantInfo";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRestaurant } from "../../../../features/reducers/restaurants";
import ListBackBtn from "../../components/ListBackBtn";

export default function RestaurantSettings() {
  //* Get the restaurant Data
  const { id } = useParams();

  // Initiate action to get restaurant data
  const dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurants.restaurant);

  useEffect(() => {
    dispatch(getRestaurant(id));
  }, [dispatch, id]);

  return (
    <>
      <ListBackBtn backURL='/business/menu' btnTxt='Back To Menu Item List' />
      <RestaurantInfo restaurant={restaurant} />
    </>
  );
}
