//** Import Components */
import React from "react";
import { Divider, Select, Space } from "antd";
import { useSelector } from "react-redux";
import { useManageBusinessItems } from "../../../hooks/useManageBusinessItems";
import { getBusinessList } from "../../../features/reducers/business";

//** Separate antd Select Components */
const { Option } = Select;

export default function DashboardSelectBusiness() {
  const businessList = useSelector((state) => state.business.businessList);
  const curBusiness = useSelector((state) => state.business.business);

  const user = useSelector((state) => state.user.user);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: user.id,
    dispatchAction: getBusinessList(),
    itemType: "business",
    modalData: {
      title: "Create New Business",
      fieldLabel: "Business Name",
    },
  };

  const [modalComponent, CreateItemButton] = useManageBusinessItems(hookData);

  // Check if we have had a preselected business
  const preSelectedBusiness = localStorage.getItem("editBusiness");

  const handleChange = (value) => {
    localStorage.setItem("editBusiness", value);

    window.location.replace("/business");
  };

  return (
    <div className="business-list-manager">
      <Select
        placeholder="Choose Business"
        className="business-selector"
        dropdownMatchSelectWidth={false}
        onSelect={handleChange}
        value={curBusiness.name}
        defaultValue={preSelectedBusiness}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />

            <Space
              align="center"
              style={{
                padding: "0 8px 4px",
              }}
            >
              <CreateItemButton btnText="Create New Business" />
            </Space>
          </>
        )}
      >
        {businessList.map((business) => (
          <Option key={business.id} value={business.id}>
            {business.name}{" "}
          </Option>
        ))}
      </Select>

      {modalComponent}
    </div>
  );
}
