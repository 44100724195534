//** Import Modules */
import React from "react";
import { Select } from "antd";

//** Separate antd Select Components */
const { Option } = Select;

const SelectDropdown = (props) => {
  return (
    <>
      <Select
        key={props.key}
        className={props.className}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onSelect={props.onSelect}
        onBlur={props.onBlur}
        disabled={props.disabled}
        showSearch={props.showSearch ? props.showSearch : false}
        allowClear={props.allowClear ? props.allowClear : false}
        style={props.style}
        mode={props.mode}
      >
        {props.options &&
          props.options.map((option) => (
            <Option key={option.key} value={option.key}>
              {option.value}
            </Option>
          ))}
      </Select>
    </>
  );
};

export default SelectDropdown;
