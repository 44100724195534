import { message, Modal, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

export default function EventAttendeeList({ ticket }) {
  const attendeeList = ticket.ordered_ticket_data;

  console.log(attendeeList);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Modal handlers
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      render: (user) => {
        return <>{user.user_fname + " " + user.user_lname}</>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Date Bought",
      dataIndex: "created",
      key: "created",
      render: (date) => {
        return moment(date).format("MM/DD/YYYY");
      },
    },
  ];

  const attendees = attendeeList.map((attendee) => {
    return {
      name: `${attendee.user_fname} ${attendee.user_lname}`,
      email: `${attendee.user_email}`,
      quantity: attendee.quantity,
      created: attendee.created,
    };
  });

  return (
    <>
      <button onClick={showModal}>Attendees</button>

      <Modal
        title='Attendees List'
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className='attendee-modal'
      >
        <div className='export-csv'>
          <CSVLink
            filename={"Event_Attendees.csv"}
            data={attendees}
            className='btn btn-primary'
            onClick={() => {
              message.success("The file is downloading");
            }}
          >
            Export Attendee List
          </CSVLink>
        </div>

        <Table dataSource={attendeeList} columns={columns} />
      </Modal>
    </>
  );
}
