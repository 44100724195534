//** Import Modules */
import React from "react";

//** Import Components */
import { Navigate } from "react-router";
import { getCookie } from "./manageCookies";

export default function PrivateRoute({ children }) {
  const getToken = getCookie("ubt");

  const redirectURL = window.location;

  if (!getToken || getToken === undefined) {
    return <Navigate to={`/?redirect=${redirectURL}`} replace />;
  }

  return children;
}
