//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setFollowersList } from "../../reducers/followers";
import { requestGetFollowersList } from "../requests/followers";

export function* handleGetFollowersList({ businessID }) {
  try {
    const response = yield call(requestGetFollowersList, businessID);
    const { data } = response;

    yield put(setFollowersList(data));
  } catch (err) {
    console.log(err.response);
  }
}
