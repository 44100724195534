//** Import Components */
import { useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Form, Input, Modal, Button, message, Table, Empty, Space, Tag } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const useManagementOrders = ({
  itemType,
  type,
  creatorID,
  user,
  modalData,
  //setState,
  dispatchAction,
  deleteItemAPI,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    setIsSubmitting(true);

    modalForm.submit();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsSubmitting(false);
  };

  //* Component that holds the modal
  const modalComponent = (
    <Modal
      visible={openModal}
      title={modalData.title}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Close
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={submitModalForm}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={modalForm}
        layout="vertical"
        name="userForm"
        //onFinish={createListItem}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="name"
          label={modalData.fieldLabel}
          rules={[
            {
              required: true,
              message: "Please input a name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );

  const CreateItemButton = ({ btnText }) => {
    return (
      <div className="create-item">
        <Button type="primary" onClick={showModal}>
          {btnText}
        </Button>
      </div>
    );
  };

  const dateFormat = TABLE_DATE_FORMAT;

  //numero de orden - fecha - status - total - acciones (view)


  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <>{text.order_id}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.date_created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {
            text.order_status == 'Placed' && console.log('Order text', text) &&
            <Tag icon={<ExclamationCircleOutlined />} color="error">
              {text && text.order_status}
            </Tag>
          }
          {
            text.order_status == 'Making' &&
            <Tag icon={<SyncOutlined spin />} color="processing">
              {text && text.order_status}
            </Tag>
          }
          {
            (text.order_status == 'On Its Way' || text.order_status == 'Ready For Pickup' ) &&
            <Tag icon={<ClockCircleOutlined />} color="warning">
              {text && text.order_status}
            </Tag>
          }
          {
            (text.order_status == 'Delivered' || text.order_status == 'Picked Up') &&
            <Tag icon={<CheckCircleOutlined />} color="success">
              {text && text.order_status}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              navigate(`${text.id}`);
            }}
          >
            View
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} />
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [modalComponent, CreateItemButton, ListComponent];
};
