import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getMessageList } from "../../../../features/reducers/messages";
import MessageItem from "./MessageItem";
import { Input } from "antd";

export default function ChatBox(props) {
  const { messageThreads, business } = props;

  // Get the info of the specific id
  const { id } = useParams();
  const currentThread = messageThreads.filter((thread) => thread.id !== id)[0];

  console.log(currentThread);

  // Get the messages
  const dispatch = useDispatch();
  const messageList = useSelector((state) => state.messages.messageList);

  useEffect(() => {
    dispatch(getMessageList(business.id, currentThread.sender_id));
  }, [dispatch, business, currentThread.sender_id]);

  return (
    <div className='chat-box'>
      <div className='message-list'>
        {messageList.map((message) => {
          return (
            <MessageItem
              key={message.id}
              message={message}
              senderInfo={currentThread.senderInfo}
            />
          );
        })}
      </div>

      <div className='send-message'>
        <Input.TextArea
          autoSize={{
            minRows: 1,
            maxRows: 5,
          }}
        />
      </div>
    </div>
  );
}
