import React, { useEffect, useState } from "react";
import { Calendar } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import moment from "moment";
import AppointmentsByMonthTable from "./AppointmentsByMonthTable";

export default function AppointmentsByMonth(props) {

  const { business } = props;

  const [listItems, SetListItems] = useState([]);
  const [appointmentByDay, setAppointmentByDay] = useState([]);

  useEffect(() => {

    const year = moment()._d.getFullYear();
    const month = getNameMonth(moment().month());
    const business_id = business.id;

    const APIURL = `/api/appointments/list/monthly`;

    const payload = {
      year,
      month,
      business_id
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        SetListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });

  }, [business.id]);

  const getNumberMonth = (month) => {
    let numberMonth;
    switch (month) {
      case 'January': numberMonth = 0; break;
      case 'February': numberMonth = 1; break;
      case 'March': numberMonth = 2; break;
      case 'April': numberMonth = 3; break;
      case 'May': numberMonth = 4; break;
      case 'June': numberMonth = 5; break;
      case 'July': numberMonth = 6; break;
      case 'August': numberMonth = 7; break;
      case 'September': numberMonth = 8; break;
      case 'October': numberMonth = 9; break;
      case 'November': numberMonth = 10; break;
      case 'December': numberMonth = 11; break;
      default:
    }
    return numberMonth;
  }

  const getNameMonth = (month) => {
    let nameMonth;
    switch (month) {
      case 0: nameMonth = 'Jan'; break;
      case 1: nameMonth = 'Feb'; break;
      case 2: nameMonth = 'Mar'; break;
      case 3: nameMonth = 'Apr'; break;
      case 4: nameMonth = 'May'; break;
      case 5: nameMonth = 'Jun'; break;
      case 6: nameMonth = 'Jul'; break;
      case 7: nameMonth = 'Aug'; break;
      case 8: nameMonth = 'Sept'; break;
      case 9: nameMonth = 'Oct'; break;
      case 10: nameMonth = 'Nov'; break;
      case 11: nameMonth = 'Dec'; break;
      default:
    }
    return nameMonth;
  }

  const onPanelChange = (value) => {
    console.log(value.format("YYYY-MM-DD"));
  };

  const onSelect = (value) => {
    let currentList = [...listItems];

    let listData = [];

    for(let i=0; i < currentList.length; i++) {
      if(moment(currentList[i].start_time)._d.getDate() === value.date()) {
        listData.push(currentList[i]);
      }
    }

    setAppointmentByDay(listData);
  };

  // const getNameMonth = (month) => {
  //   let nameMonth;
  //   switch (month) {
  //     case 0: nameMonth = 'January'; break;
  //     case 1: nameMonth = 'February'; break;
  //     case 2: nameMonth = 'March'; break;
  //     case 3: nameMonth = 'April'; break;
  //     case 4: nameMonth = 'May'; break;
  //     case 5: nameMonth = 'June'; break;
  //     case 6: nameMonth = 'July'; break;
  //     case 7: nameMonth = 'August'; break;
  //     case 8: nameMonth = 'September'; break;
  //     case 9: nameMonth = 'October'; break;
  //     case 10: nameMonth = 'November'; break;
  //     case 11: nameMonth = 'December'; break;
  //     default:
  //   }
  //   return nameMonth;
  // }

  return (
    <div className="calendar_table_container">
      <div className="calendar_container">
        <Calendar fullscreen={false} onPanelChange={onPanelChange} onSelect={onSelect}/>
      </div>
      <div className="appointment_table_container">
        <AppointmentsByMonthTable appointmentByDay={appointmentByDay} />
      </div>
    </div>
  );
}
