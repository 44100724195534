const dashboardMenu = [
  {
    title: "Main Settings",
    key: "home",
    capability: "manage_business",
    icon: "user-gear",
    items: [],
  },
  {
    title: "Zoom Settings",
    key: "zoom-settings",
    capability: "access_networking_plus",
    icon: "video",
    items: [],
  },
  {
    title: "My Businesses",
    key: "my-businesses",
    capability: "manage_business",
    icon: "briefcase",
    items: [],
  },
  {
    title: "Services",
    key: "my-services",
    capability: "manage_services",
    icon: "briefcase",
    items: [],
  },
  {
    title: "Billing History",
    key: "billing-history",
    capability: "manage_business",
    icon: "file-invoice-dollar",
    items: [],
  },
  {
    title: "Appointments",
    key: "user_appointments",
    capability: "manage_business",
    icon: "calendar-check",
    items: [],
  },
  // {
  //   title: "Orders",
  //   key: "user_orders",
  //   capability: "manage_business",
  //   icon: "file-lines",
  //   items: [],
  // },
  {
    title: "Membership",
    key: "membership",
    capability: "manage_business",
    icon: "id-card",
    items: [],
  },
];

module.exports = {
  dashboardMenu,
};
