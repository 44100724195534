//** Import Modules */
import { takeLatest, all } from "redux-saga/effects";

//** Import Handler functions */
import { handleGetBusiness, handleGetBusinessList } from "./handlers/business";
import { handleGetAd, handleGetAdsList } from "./handlers/ads";
import { handleGetEvent, handleGetEventsList } from "./handlers/events";
import { handleGetService, handleGetServicesList } from "./handlers/services";
import { handleGetRestaurant, handleGetRestaurantsList } from "./handlers/restaurants";
import { handleGetGroups, handleGetGroupsList } from "./handlers/groups";
import { handleGetUser } from "./handlers/user";
import { handleGetReviewsList } from "./handlers/reviews";
import {
  handleGetMessageList,
  handleGetMessageThreads,
} from "./handlers/messages";
import { handleGetFollowersList } from "./handlers/followers";

//** Import Actions */
import { GET_BUSINESS, GET_BUSINESS_LIST } from "../reducers/business";
import { GET_GROUP, GET_GROUP_LIST } from "../reducers/groups";
import { GET_EVENTS, GET_EVENTS_LIST } from "../reducers/events";
import { GET_SERVICES, GET_SERVICES_LIST } from "../reducers/services";
import { GET_RESTAURANTS, GET_RESTAURANTS_LIST } from "../reducers/restaurants";
import { GET_ADS, GET_ADS_LIST } from "../reducers/ads";
import { GET_USER } from "../reducers/user";
import { GET_REVIEWS_LIST } from "../reducers/reviews";
import { GET_FOLLOWERS_LIST } from "../reducers/followers";
import { GET_MESSAGE_LIST, GET_MESSAGE_THREADS } from "../reducers/messages";
import { GET_NOTIFICATIONS } from "../reducers/notifications";
import { handleGetNotifications } from "./handlers/notifications";

export function* watcherSaga() {
  yield all([
    yield takeLatest(GET_BUSINESS_LIST, handleGetBusinessList),
    yield takeLatest(GET_BUSINESS, handleGetBusiness),
    yield takeLatest(GET_GROUP_LIST, handleGetGroupsList),
    yield takeLatest(GET_GROUP, handleGetGroups),
    yield takeLatest(GET_EVENTS_LIST, handleGetEventsList),
    yield takeLatest(GET_EVENTS, handleGetEvent),
    yield takeLatest(GET_SERVICES_LIST, handleGetServicesList),
    yield takeLatest(GET_SERVICES, handleGetService),
    yield takeLatest(GET_RESTAURANTS_LIST, handleGetRestaurantsList),
    yield takeLatest(GET_RESTAURANTS, handleGetRestaurant),
    yield takeLatest(GET_ADS_LIST, handleGetAdsList),
    yield takeLatest(GET_ADS, handleGetAd),
    yield takeLatest(GET_USER, handleGetUser),
    yield takeLatest(GET_REVIEWS_LIST, handleGetReviewsList),
    yield takeLatest(GET_FOLLOWERS_LIST, handleGetFollowersList),
    yield takeLatest(GET_MESSAGE_THREADS, handleGetMessageThreads),
    yield takeLatest(GET_MESSAGE_LIST, handleGetMessageList),
    yield takeLatest(GET_NOTIFICATIONS, handleGetNotifications),
  ]);
}
