//** Import Modules */
import React, { useRef, useState } from "react";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

//** Import Components */
import axiosGeneral from "../../../utils/axioGenetal";
import Terms from "../../PolicyPages/Terms";
import { getCookie } from "../../../common/manageCookies";
import { GOOGLE_RECAPTCHA_SITEKEY } from "../../../common/constants";
import { Helmet } from "react-helmet";

export default function RegistrationForm(props) {
  const { setToggleForm } = props;

  const signupForm = useRef(null);
  const recaptchaRef = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isValidCaptcha, setIsValidCaptcha] = useState("");

  const referralCode = getCookie("referral");

  const handleOnChange = (value) => {
    if (value) {
      setIsValidCaptcha("");
    }
  };

  const onFinish = async (values) => {
    const APIURL = "/api/users/register";

    let user_meta = [
      {
        meta_key: "is_user_verified",
        meta_value: false,
      },
    ];

    if (referralCode && referralCode !== undefined) {
      user_meta.push({
        meta_key: "referrer",
        meta_value: referralCode,
      });
    }

    const user = {
      user_login: values.user_login,
      user_pass: values.user_pass,
      user_email: values.user_email,
      user_fname: values.user_fname,
      user_lname: values.user_lname,
      user_meta,
    };

    const captchaToken = await recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    console.log(user);

    axiosGeneral()
      .post(APIURL, { data: user, captchaToken })
      .then((res) => {
        signupForm.current.resetFields();

        Modal.success({
          title: "User has been created successfully",
          content: `Please check the email you supplied in the form to verify your account before you can use it to post content`,
          okText: "Login",
          onOk: () => {
            window.location.replace("/");
          },
        });
      })
      .catch((err) => {
        let errorMessage = err.response.data.message;

        Modal.error({
          title: "There was an error creating user",
          content: errorMessage,
        });

        console.log(err.response);

        if (err.response.data.error.includes("Duplicate")) {
          errorMessage =
            "The email you have entered is already in use by another user";
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Helmet>
        <title>Register - BIZZLL Dashboard</title>
      </Helmet>

      <div id="registration-form">
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={signupForm}
        >
          <Form.Item
            name="user_fname"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="user_lname"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="user_email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
              {
                type: "email",
                message: "This is not a valid email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="user_login"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="user_pass"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirm"
            dependencies={["user_pass"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("user_pass") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>
              By checking this, you are agreeing to our{" "}
              <span
                role="button"
                onClick={() => {
                  setIsModalVisible(true);
                }}
                className="link-btn"
              >
                Terms and Conditions
              </span>
            </Checkbox>
          </Form.Item>

          <div className="captcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={GOOGLE_RECAPTCHA_SITEKEY}
              size="normal"
              className="g-recaptcha"
              onChange={handleOnChange}
            />
            {isValidCaptcha && (
              <h4
                style={{
                  color: "#F00000",
                }}
              >
                {isValidCaptcha}
              </h4>
            )}
          </div>

          <div className="bottom-buttons">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>

            <div
              role="button"
              className="link-btn"
              onClick={() => {
                setToggleForm("login");
              }}
            >
              Already have an account? Sign In!
            </div>
          </div>
        </Form>

        <Modal
          title="Terms and Conditions"
          visible={isModalVisible}
          onOk={() => {
            setIsModalVisible(false);
          }}
          wrapClassName="terms-modal"
          onCancel={() => {
            setIsModalVisible(false);
          }}
          width={1200}
        >
          <Terms />
        </Modal>
      </div>
    </>
  );
}
