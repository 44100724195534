//** Import Modules */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";

//** Import Components */
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import { getGroup } from "../../../../features/reducers/groups";
import GroupInfo from "./GroupInfo";
import GroupMembers from "./GroupMembers";

export default function GroupSettings() {
  //* Get the group Data
  const { id } = useParams();

  // Initiate action to get event data
  const dispatch = useDispatch();
  const group = useSelector((state) => state.groups.group);

  useEffect(() => {
    dispatch(getGroup(id));
  }, [dispatch, id]);

  return (
    <div>
      <ListBackBtn backURL='/business/groups' btnTxt='Back To Groups List' />

      <Tabs defaultActiveKey='1' type='card' size='default'>
        <Tabs.TabPane tab='Group Information' key='1'>
          <GroupInfo group={group} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Group Members' key='2'>
          <GroupMembers group={group} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
