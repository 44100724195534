//** Import Modules */
import React from "react";
import { Form, Input, Button, Alert } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";

//** Import Custom Hooks */
import { useLoginUser } from "../../../hooks/useLoginUser";
import { Helmet } from "react-helmet";

export default function LoginForm(props) {
  const { setToggleForm } = props;

  const [logUserIn, isSubmitting, response] = useLoginUser();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //
  const URLQueryVars = new URL(document.location).searchParams;
  const pr = URLQueryVars.get("pr");

  return (
    <div id="login-form">
      <Helmet>
        <title>Login - BIZZLL Dashboard</title>
      </Helmet>

      {pr && (
        <Alert
          message="Password has been reset successfully. You may login using your new password."
          type="success"
          style={{ marginBottom: 15 }}
        />
      )}

      <Form
        layout="vertical"
        onFinish={(values) => {
          logUserIn(values);
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input
            disabled={isSubmitting}
            placeholder="Username Or Email"
            prefix={<FontAwesomeIcon icon={faUser} />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            disabled={isSubmitting}
            placeholder="Password"
            prefix={<FontAwesomeIcon icon={faKey} />}
          />
        </Form.Item>

        <div
          onClick={(e) => {
            setToggleForm("forgot");
          }}
          role="button"
          className="link-btn"
        >
          Forgot your password?
        </div>

        <div className="bottom-buttons">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Logging in" : "Login"}
            </Button>
          </Form.Item>

          <div
            role="button"
            className="link-btn"
            onClick={() => {
              setToggleForm("register");
            }}
          >
            Don't have an account? Create One!
          </div>
        </div>
      </Form>

      {response !== "" && response}
    </div>
  );
}
