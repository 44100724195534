import { Avatar } from "antd";
import React, { useEffect, useState } from "react";

export default function UserAvatar({ user, size, single }) {
  const [userAvatarImage, setUserAvatarImage] = useState("");

  useEffect(() => {
    if (user.meta_data && user.meta_data.length > 0) {
      const getUserAvatarImage = user.meta_data.filter(
        (b) => b.meta_key === "user_logo"
      )[0];

      if (getUserAvatarImage) {
        setUserAvatarImage(getUserAvatarImage.meta_value);
      }
    }
  }, [user]);

  return (
    <>
      {userAvatarImage || userAvatarImage !== "" ? (
        <Avatar size={size} src={userAvatarImage} />
      ) : (
        <Avatar size={size}>
          {user.user_fname.charAt(0)} {!single && user.user_lname.charAt(0)}
        </Avatar>
      )}
    </>
  );
}
