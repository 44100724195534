//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setNotifications } from "../../reducers/notifications";
import { requestGetNotifications } from "../requests/notifications";

export function* handleGetNotifications() {
  try {
    const response = yield call(requestGetNotifications);
    const { data } = response;

    const sortedNotifications = data.sort(function (a, b) {
      return new Date(b.date_notified) - new Date(a.date_notified);
    });

    yield put(setNotifications(data));
  } catch (err) {
    console.log(err.response);
  }
}
