import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManagementOrders } from "../../../../hooks/useManagementOrders";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getRestaurantsList } from "../../../../features/reducers/restaurants";
import OrderSettings from "./OrderSettings";
import { Alert } from "antd";

export default function ManageOrders(props) {
    const { business } = props;

    // Initiate action to get user's business list
    const user = useSelector((state) => state.user.user);

    const dispatch = useDispatch();
    // const restaurantsList = useSelector((state) => {
    //     console.log('state', state)
    //     return state.restaurants.restaurantsList;
    // });

    const [ordersList, setOrdersList] = useState([{
        order_id: 123,
        order_status: "Making",
        order_total: 19.99,
        date_created: '2023-06-09T14:00:00',
        order_items: [
            {
                product_id: 1,
                product_price: 19.99,
                product_name: "Pizza con queso",
                product_quantity: 3
            },
            {
                product_id: 2,
                product_price: 24.99,
                product_name: "Pizza con queso y chorizo",
                product_quantity: 2
            }
        ]
        },
        {
            order_id: 333,
            order_status: "Ready For Pickup",
            order_total: 59.99,
            date_created: '2023-06-16T14:00:00',
            order_items: [
                {
                    product_id: 1,
                    product_price: 19.99,
                    product_name: "Pizza con qpeperon",
                    product_quantity: 3
                },
                {
                    product_id: 2,
                    product_price: 24.99,
                    product_name: "Pizza con bacon",
                    product_quantity: 2
                }
            ]
        },
        {
            order_id: 333,
            order_status: "Placed",
            order_total: 59.99,
            date_created: '2023-06-16T14:00:00',
            order_items: [
                {
                    product_id: 1,
                    product_price: 19.99,
                    product_name: "Pizza con peperoni",
                    product_quantity: 3
                },
                {
                    product_id: 2,
                    product_price: 24.99,
                    product_name: "Pizza con bacon",
                    product_quantity: 2
                }
            ]
        },
        {
            order_id: 333,
            order_status: "Picked Up",
            order_total: 59.99,
            date_created: '2023-06-16T14:00:00',
            order_items: [
                {
                    product_id: 1,
                    product_price: 19.99,
                    product_name: "Pizza con qpeperon",
                    product_quantity: 3
                },
                {
                    product_id: 2,
                    product_price: 24.99,
                    product_name: "Pizza con bacon",
                    product_quantity: 2
                }
            ]
        }

    ])

    useEffect(() => {
        dispatch(getRestaurantsList(business.id));
    }, [business.id, dispatch]);

    // Custom hook to manage the creation/deletion of business listing
    const hookData = {
        creatorID: business.id,
        dispatchAction: getRestaurantsList(business.id),
        deleteItemAPI: "/api/products/delete",
        user: user,
        itemType: "products",
        type: "restaurant",
        modalData: {
            title: "Create New Menu Item",
            fieldLabel: "Menu Item Name",
        },
    };

    const [modalComponent, CreateItemButton, ListComponent] =
        useManagementOrders(hookData);

    return (
        <div id="restaurant-page">
            <ContentPageTitle title="Manage Orders" icon="file-pen" />

            <div className="content-box">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <ListComponent
                                    emptyMessage="Seems like you don't have an restaurants listing."
                                    btnText="Create New Restaurant"
                                    listItems={ordersList}
                                />
                            </>
                        }
                        exact
                    />

                    <Route path="/:id" element={<OrderSettings ordersList={ordersList} />} exact />
                </Routes>
            </div>

            {modalComponent}
        </div>
    );
}
