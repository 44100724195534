import React from "react";

export default function RestrictedContent() {
  return (
    <div id='restricted-content'>
      <h1>Restricted Page</h1>

      <p>You do not have the necessary privileges to access this page</p>
    </div>
  );
}
