import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { Tabs } from "antd";

export default function ManagePolicys(props) {

  const { business } = props;

  return (
    <div id="appointments-page">
      <ContentPageTitle title="My Appointments" icon="calendar-check" />
      <div className="content-box">
        <p>Hello World!</p>
      </div>
    </div>
  );
}
