//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetService(serviceID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/products/${serviceID}`,
  });
}

export function requestGetServicesList(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/products/business/${businessID}/service`,
  });
}

export function requestGetServicesAvailabilityRules(serviceID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/product/${serviceID}`,
  });
}
