import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import React from "react";

export default function DateRangeChooser(props) {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className='date-range'>
      <Select
        defaultValue='cur-week'
        style={{
          width: 200,
        }}
        onChange={handleChange}
      >
        <Option value='cur-week'>This Week</Option>
        <Option value='last-week'>Last Week</Option>
        <Option value='cur-month'>This Month</Option>
        <Option value='last-month'>Last Month</Option>
      </Select>
    </div>
  );
}
