//** Import Modules */
import { call, put } from "redux-saga/effects";
import { setAd, setAdsList } from "../../reducers/ads";
import { requestGetAd, requestGetAdList } from "../requests/ads";

export function* handleGetAd({ adID }) {
  try {
    const response = yield call(requestGetAd, adID);
    const { data } = response;

    yield put(setAd(data));
  } catch (err) {
    console.log(err.response);
  }
}

export function* handleGetAdsList({ businessID }) {
  try {
    const response = yield call(requestGetAdList, businessID);
    const { data } = response;

    yield put(setAdsList(data));
  } catch (err) {
    console.log(err.response);
  }
}
