//** Import Modules */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

export default function PrivateInternalRoute({ children, capability }) {
  // Get User Info
  const user = useSelector((state) => state.user.user);
  const roleCapabilities = user.role ? user.role.capabilities : [];

  const [allowRouteAccess, setAllowRouteAccess] = useState(true);

  useEffect(() => {
    if (roleCapabilities.length > 0 && !roleCapabilities.includes(capability)) {
      setAllowRouteAccess(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!allowRouteAccess) {
    return <Navigate to='/restricted-page' replace />;
  }

  return children;
}
