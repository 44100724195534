import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import { DASHBOARD_URL } from "../../../common/constants";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useDispatch } from "react-redux";
import { getUser } from "../../../features/reducers/user";

export default function ReferralURL({ user }) {
  const [referralURL, setReferralURL] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (user.meta_data) {
      const referralCode = user.meta_data.filter(
        (b) => b.meta_key === "referral_code"
      )[0];

      if (referralCode !== undefined) {
        const createURL = `${DASHBOARD_URL}/ref/${referralCode.meta_value}`;

        setReferralURL(createURL);
      }
    }
  }, [user]);

  const dispatch = useDispatch();

  const generateCode = () => {
    const APIURL = "api/users/referral/generateCode";

    setSubmitting(true);

    axiosWithAuth()
      .post(APIURL)
      .then((res) => {
        setSubmitting(false);

        dispatch(getUser());

        Modal.success({
          title: "Code Created Successfully",
          content: "You now have your referral URL ready to use.",
        });

        console.log(res);
      })
      .catch((err) => {
        setSubmitting(false);

        console.log(err.response);

        Modal.error({
          title: "Error creating Code",
          content: err.response.data.message,
        });
      });
  };

  return (
    <div className='referral-url'>
      {referralURL === "" ? (
        <>
          <Button
            onClick={generateCode}
            type='primary'
            loading={submitting}
            disabled={submitting}
          >
            Generate Code
          </Button>
        </>
      ) : (
        <>
          <div className='referral-url-txt'>{referralURL}</div>
          <Tooltip title='copy Referral url'>
            <Button
              onClick={() =>
                navigator.clipboard
                  .writeText(referralURL)
                  .then(() => {
                    alert("Text Copied");
                  })
                  .catch((err) => {
                    alert("Error");
                  })
              }
            >
              Copy
            </Button>
          </Tooltip>
        </>
      )}
    </div>
  );
}
