//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetBusiness(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/business/${businessID}`,
  });
}

export function requestGetBusinessList() {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/business/owner`,
  });
}

export function requestGetConnectAccountDetails(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/stripe/connect/business/${businessID}`,
  });
}
