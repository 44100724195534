import React, { useEffect, useState } from "react";
import { DatePicker, Divider } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import { useManagementAppointments } from "../../../../hooks/useManagementAppointments";
import { Routes, Route } from "react-router-dom";
import AppointmentsByWeekInfo from "./AppointmentsByWeekInfo";
import moment from "moment";
import dayjs from 'dayjs';


export default function AppointmentsByWeek(props) {

  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  const [listItems, SetListItems] = useState([]);
  const [CurrentDate, SetCurrentDate] = useState(moment());

  const weekFormat = 'MM/DD/YY';

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value)
      .endOf('week')
      .format(weekFormat)}`;

  useEffect(() => {

    const year = moment()._d.getFullYear();
    const weekNum = moment().week();
    const business_id = business.id;

    const APIURL = `/api/appointments/list/weekly`;

    const payload = {
      year,
      weekNum,
      business_id
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        SetListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });

  }, [business.id]);

  const onChange = (value) => {

    SetCurrentDate(value);

    const year = value._d.getFullYear();
    const weekNum = value.week();

    const business_id = business.id;

    const APIURL = `/api/appointments/list/weekly`;

    const payload = {
      year,
      weekNum,
      business_id
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
        SetListItems(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // Custom hook to manage the listing
  const hookData = {
    creatorID: user.id,
    itemType: "products",
    type: "restaurant",
  };
  
  const [ListComponent] = useManagementAppointments(hookData);

  return (
    <>
	    <div id="orders-page">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <DatePicker onChange={onChange} value={CurrentDate} format={customWeekStartEndFormat} picker='week' />
                <Divider/>
                <ListComponent
                  emptyMessage="Seems like you don't have an appointment listing."
                  listItems={listItems}
                />
              </>
            }
            exact
          />
          <Route path="/:id" element={<AppointmentsByWeekInfo business={business} />} exact />
        </Routes>
      </div>
    </>
  );
}
