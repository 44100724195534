//** Define Actions */
export const GET_GROUP = "GET_GROUP";
export const SET_GROUP = "SET_GROUP";
export const GET_GROUP_LIST = "GET_GROUP_LIST";
export const SET_GROUP_LIST = "SET_GROUP_LIST";

//** Create action functions */
export const getGroup = (groupID) => ({
  type: GET_GROUP,
  groupID,
});

export const setGroup = (group) => ({
  type: SET_GROUP,
  group,
});

export const getGroupList = () => ({
  type: GET_GROUP_LIST,
});

export const setGroupList = (groupList) => ({
  type: SET_GROUP_LIST,
  groupList,
});

//** Create initial state */
const initialState = {
  groupList: [],
  group: {
    groupMembers: [],
  },
};

//** Create the handler function */
const groupsReducer = (state = initialState, { type, group, groupList }) => {
  switch (type) {
    case SET_GROUP:
      return {
        ...state,
        group,
      };
    case SET_GROUP_LIST:
      return {
        ...state,
        groupList: groupList.ownGroup.map((group) => {
          return {
            ...group,
            key: group.id,
          };
        }),
      };
    default:
      return state;
  }
};

export default groupsReducer;
