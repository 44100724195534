import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import AnalyticsBoxCounter from "../../../../common/AnalyticsBoxCounter";
import AgeGroups from "./DemographicComponents/AgeGroups";
import GenderGroups from "./DemographicComponents/GenderGroups";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import {
  BLUE_COLOR,
  GREEN_COLOR,
  PURPLE_COLOR,
  RED_COLOR,
} from "../../../../common/constants";
import moment from "moment";
import DateRangeChooser from "../../components/DateRangeChooser";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ManageAnalytics({ business }) {
  // Set date format
  const dateFormat = "MMM D, YYYY";

  // Separate the analytics data
  const [visitors, setVisitors] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [address, setAddress] = useState([]);
  const [phone, setPhone] = useState([]);

  // Get analytics data
  useEffect(() => {
    if (business.id) {
      const APIURL = `/api/business/analytics/${business.id}`;

      axiosWithAuth()
        .get(APIURL)
        .then((res) => {
          console.log(res);

          const visitorData = res.data.analytics.filter(
            (item) => item.component === "profile_visit"
          );

          const addressData = res.data.analytics.filter(
            (item) => item.component === "address_click"
          );

          const phoneData = res.data.analytics.filter(
            (item) => item.component === "phone_click"
          );

          setVisitors(visitorData);
          setFollowers(res.data.followers);
          setAddress(addressData);
          setPhone(phoneData);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }

    getCurrentWeek();
  }, [business]);

  // Get current week
  const getCurrentWeek = () => {
    var currentDate = moment();

    var weekStart = currentDate.clone().startOf("week");

    var days = [];

    for (var i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, "days").format(dateFormat));
    }

    return days;
  };

  // Prepare data for graph
  const getDataCount = (data, date) => {
    const filteredData = data.filter((item) => {
      const itemDate = moment(item.date_recorded).format(dateFormat);

      return itemDate === date;
    });

    return filteredData.length;
  };

  const labels = getCurrentWeek();

  const data = {
    labels,
    datasets: [
      {
        label: "Visits",
        data: labels.map((date) => {
          return getDataCount(visitors, date);
        }),
        borderColor: PURPLE_COLOR,
        backgroundColor: PURPLE_COLOR,
      },
      {
        label: "Followers",
        data: labels.map((date) => {
          return getDataCount(followers, date);
        }),
        borderColor: RED_COLOR,
        backgroundColor: RED_COLOR,
      },
      {
        label: "Get Directions Clicks",
        data: labels.map((date) => {
          return getDataCount(address, date);
        }),
        borderColor: GREEN_COLOR,
        backgroundColor: GREEN_COLOR,
      },
      {
        label: "Phone Clicks",
        data: labels.map((date) => {
          return getDataCount(phone, date);
        }),
        borderColor: BLUE_COLOR,
        backgroundColor: BLUE_COLOR,
      },
    ],
  };

  return (
    <div>
      <ContentPageTitle title='Business Insights' icon='chart-column' />

      <div className='content-box'>
        <div className='analytics-container'>
          <DateRangeChooser />

          <div className='main-stats'>
            <AnalyticsBoxCounter
              title='Visits'
              stat={visitors.length}
              color='purple'
            />
            <AnalyticsBoxCounter
              title='Followers'
              stat={followers.length}
              color='red'
            />
            <AnalyticsBoxCounter
              title='Directions Clicks'
              stat={address.length}
              color='green'
            />
            <AnalyticsBoxCounter
              title='Phone Clicks'
              stat={phone.length}
              color='blue'
            />
          </div>

          <div className='graph'>
            <Line data={data} height={80} />
          </div>

          <div className='demographics'>
            <h3>Demographics</h3>

            <AgeGroups />
            <GenderGroups />
          </div>
        </div>
      </div>
    </div>
  );
}
