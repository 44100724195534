//** Import Modules */
import React from "react";
import { Divider } from "antd";

//** Import Components */
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import ReferralURL from "../../components/ReferralURL";
import PayoutSection from "./PayoutSection";

export default function MainSettings(props) {
  const { user, baseURL } = props;

  return (
    <div>
      <ContentFormSubtitle
        title="Referral URL"
        subtitle="Copy/paste this URL to send to your referrals. If it is your first time, you will need to generate a code first"
      />

      <ReferralURL user={user} />

      <Divider />

      <PayoutSection user={user} baseURL={baseURL} />
    </div>
  );
}
