import React, { useEffect } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import HelpButton from "../../../../common/HelpButton";
import { useMangeManagementItem } from "../../../../hooks/useMangeManagementItem";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getServicesList } from "../../../../features/reducers/services";
import ServiceSettings from "./ServiceSettings";
import { Alert } from "antd";

export default function ManageService(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const servicesList = useSelector((state) => {
    console.log('state', state)
    return state.services.servicesList;
  });

  useEffect(() => {
    dispatch(getServicesList(business.id));
  }, [business.id, dispatch]);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    creatorID: business.id,
    dispatchAction: getServicesList(business.id),
    deleteItemAPI: "/api/products/delete",
    user: user,
    itemType: "products",
    type: "service",
    modalData: {
      title: "Create New Service",
      fieldLabel: "Service Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useMangeManagementItem(hookData);

  return (
    <div id="services-page">
      <ContentPageTitle title="My Services" icon="file-pen" />
      <HelpButton helpText="asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps
                            asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps
                            asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps
                            asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps
                            asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps
                            asdjkasdhaolsjdpasjd apsdapsdjaposdja psd pasd pasdpoasdpapsdapso adpsdapsdkapdaps" />

      <div className="content-box">
        <div className="warning-boxes">
          <Alert
            message="Live Broadcasting"
            description={
              <>
                Before you can broadcast online live services, please enter your
                Zoom details as per your Zoom settings. Please{" "}
                <Link to="/business/zoom-settings">Click Here</Link> to set your
                Zoom settings now.
              </>
            }
            type="warning"
          />
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an service listing."
                  btnText="Create New Service"
                  listItems={servicesList}
                />

                <CreateItemButton btnText="Create New Service" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<ServiceSettings />} exact />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
