import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import AppointmentsByMonth from "./AppointmentsByMonth";
import AppointmentsByWeek from "./AppointmentsByWeek";
import { Tabs } from "antd";

export default function ManageAppointments(props) {

  const { business } = props;

  return (
    <div id="appointments-page">
      <ContentPageTitle title="My Appointments" icon="calendar-check" />

      <div className="content-box">
        <Tabs defaultActiveKey="1" type="card" size="default">
          <Tabs.TabPane tab="Month View" key="1">
            <AppointmentsByMonth business={business}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Week View" key="2">
            <AppointmentsByWeek business={business}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}
