import React, { useEffect, useState } from "react";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import { Table } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function ManageReferrals({ user }) {
  const [referralList, setReferralList] = useState([]);

  console.log(referralList);

  useEffect(() => {
    if (user.meta_data && user.meta_data.length > 0) {
      const referralCode = user.meta_data.filter(
        (b) => b.meta_key === "referral_code"
      )[0];

      if (referralCode !== undefined) {
        const APIURL = `/api/users/referrals/list/${referralCode.meta_value}`;

        axiosWithAuth()
          .get(APIURL)
          .then((res) => {
            setReferralList(res.data);
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    }
  }, [user]);

  const columns = [
    {
      title: "Name",
      key: "referral_name",
      render: (text) => (
        <>
          {text.user_fname} {text.user_lname}
        </>
      ),
    },
    {
      title: "Username",
      key: "referral_username",
      render: (text) => <>{text.user_login}</>,
    },
    {
      title: "Email",
      key: "referral_email",
      render: (text) => <>{text.user_email}</>,
    },
  ];

  return (
    <div>
      <ContentFormSubtitle
        title="Referrals"
        subtitle="Below is the list of users that have signed up using your referral code"
      />

      {referralList.length > 0 ? (
        <Table columns={columns} dataSource={referralList} />
      ) : (
        <h2>Currently, you have no referrals</h2>
      )}
    </div>
  );
}
