import { Button, Divider, Form, Input } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import LoadingForm from "../../../../common/LoadingForm";
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import { getUser } from "../../../../features/reducers/user";

export default function ZoomSettings({ user }) {
  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(user);

  useEffect(() => {
    if (user.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <Helmet>
        <title>Zoom Settings - BIZZLL Dashboard</title>
      </Helmet>

      <h2>Zoom Settings</h2>

      <div className="content-box">
        <ContentFormSubtitle
          title="Meeting Settings"
          subtitle="In order to use Zoom within Bizzll for Live event, you will need to add your Zoom PMI(Private Meeting ID) and passcode. Your transmissions depend on your zoom account, the membership will dictate the amount of audience and transmission time allowed."
        />

        {loadingForm ? (
          <LoadingForm />
        ) : (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            initialValues={fields}
            onFinishFailed={onFinishFailed}
            onFinish={async (values) => {
              values["user_login"] = user.user_login;
              submitForm(values, `/api/users/user/update`, getUser());
            }}
            disabled={isSubmitting}
          >
            <div className="form-row">
              <Form.Item
                label="ZOOM PMI"
                name="meta_zoom_pmi"
                tooltip="You will need to provide a ZOOM PMI(Private Meeting ID)."
                rules={[
                  {
                    required: true,
                    message: "Please input your ZOOM PMI!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                label="ZOOM Passcode"
                name="meta_zoom_passcode"
                tooltip="You will need to provide a ZOOM passcode."
                rules={[
                  {
                    required: true,
                    message: "Please input your ZOOM passcode!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <Divider />

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? "Saving" : "Save Settings"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
}
