//** Import Components */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function LoadingPage() {
  return (
    <div id='page-loader'>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
}
