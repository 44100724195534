//** Define Actions */
export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENTS = "SET_EVENTS";
export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const SET_EVENTS_LIST = "SET_EVENTS_LIST";

//** Create action functions */
export const getEvent = (eventID) => ({
  type: GET_EVENTS,
  eventID,
});

export const setEvent = (event) => ({
  type: SET_EVENTS,
  event,
});

export const getEventsList = (businessID) => ({
  type: GET_EVENTS_LIST,
  businessID,
});

export const setEventsList = (eventsList) => ({
  type: SET_EVENTS_LIST,
  eventsList,
});

//** Create initial state */
const initialState = {
  eventsList: [],
  event: {
    tickets: [],
  },
};

//** Create the handler function */
const eventsReducer = (state = initialState, { type, event, eventsList }) => {
  switch (type) {
    case SET_EVENTS:
      return {
        ...state,
        event,
      };
    case SET_EVENTS_LIST:
      return {
        ...state,
        eventsList: eventsList.map((event) => {
          return {
            ...event,
            key: event.id,
          };
        }),
      };
    default:
      return state;
  }
};

export default eventsReducer;
