import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Divider, Select, Button, Checkbox, InputNumber,  message, TimePicker, Modal, Table } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { TABLE_DATE_FORMAT } from "../../../common/constants";

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../common/LoadingForm";
import ContentPageTitle from "../../../common/ContentPageTitle";
import { getRestaurant } from "../../../features/reducers/restaurants";
import UploadMedia from "../../../common/FormInputs/UploadMedia";

export default function OrderInfoUser(props) {
    const { order } = props;

    const dateFormat = TABLE_DATE_FORMAT;

    const columns = [
        {
            title: "Product",
            key: "product_name",
            render: (text) => <>{text.product_name} x {text.product_quantity}</>,
        },
        {
            title: "Total",
            key: "total",
            render: (text) => (
                <>{text.product_price * text.product_quantity} </>
            ),
        },
    ];

    return (
        <>
            <Divider />
            <p>Order #{order.order_id} was placed on {moment(new Date(order.date_created)).format(dateFormat)} and is currently {order.order_status}</p>

            <Table columns={columns} dataSource={order.order_items} />
            
            <p><b>Subtotal: </b> {order.order_total}</p>
            <p><b>Total: </b> {order.order_total}</p>

            <Divider />
        </>
    );
}
