//** Import Modules */
import React, { useState } from "react";
import EventTicketForm from "./EventTicketForm";

export default function EditEventTicketBtn(props) {
  const { event, editedData } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Modal handlers
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <button onClick={showModal}>Edit</button>

      <EventTicketForm
        event={event}
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        editedData={editedData}
      />
    </>
  );
}
