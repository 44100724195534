import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Divider, Select, Button, Checkbox, InputNumber,  Tooltip, TimePicker, Modal } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../../common/LoadingForm";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { getService } from "../../../../features/reducers/services";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../common/constants";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";

export default function ServiceInfo(props) {
    const { service } = props;
    console.log('service', service);

    // Used to build the form
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const [
        fields,
        setFieldData,
        isSubmitting,
        submitForm,
        onFinishFailed,
        loadingForm,
    ] = useDashboardFormData(service);

    const [AvailabilityRulesList, SetAvailabilityRulesList] = useState([{id: null, from: '0:0', to: '23:59' }]);
    const [AvailabilityDays, SetAvailabilityDays] = useState({id: null, days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']});
    const [AvailabilityRulesListDelete, SetAvailabilityRulesListDelete] = useState([]);

    const priceText = <span>This price is for a block of time</span>;
    const options = ['Show', 'Hide', 'Center'];
    const [arrow, setArrow] = useState('Show');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
          return false;
        }
    
        if (arrow === 'Show') {
          return true;
        }
    
        return {
          pointAtCenter: true,
        };
      }, [arrow]);

    const format = 'HH:mm';

    const onFinish = async (values) => {

        if (AvailabilityDays.days.length === 0) {
            const modal = Modal.error({
                title: "You must select at least one day to provide the service",
            });

            setTimeout(() => {
                modal.destroy();
            }, 5000);
        } else {
            //Add availability rule days
            let availability_rule_day = {
                rule_type: 'Range of days',
                rule_val_1: AvailabilityDays.days,
                rule_val_2: '',
            }
            if (AvailabilityDays.id === null) {
                createNewAvailabilityRuleDays(availability_rule_day);
            } else {
                updateAvailabilityRuleDays(availability_rule_day, AvailabilityDays.id);
            }
            //Add availability rule hours
            AvailabilityRulesList.map((dateRange) => {
                let availability_rule_hour = {
                    rule_type: 'Range of hours',
                    rule_val_1: dateRange.from,
                    rule_val_2: dateRange.to,
                }
                if (dateRange.id === null) {
                    createNewAvailabilityRuleHours(availability_rule_hour);
                } else {
                    updateAvailabilityRuleHours(availability_rule_hour, dateRange.id);
                }
            });
            //Delete availability rule hours
            AvailabilityRulesListDelete.map((availability_rule_delete) => {
                deleteAvailabilityRuleHours(availability_rule_delete);
            });

            values.meta_service_duration_time =
                values.meta_service_duration_time.format("HH:mm");


            submitForm(
                values,
                `/api/products/update/${service.id}`,
                getService(service.id)
            );
        }
    };

    const ChangeAvailabilityStartHour = (value, index) => {
        const currentAvailabilityRulesList = [...AvailabilityRulesList];

        var isSelected = false;
        var startHourNew = moment(value.getHours() + ':' + value.getMinutes(), format)

        for (let i=0; i < currentAvailabilityRulesList.length; i++) {
            var startHourList = moment(currentAvailabilityRulesList[i].from, format)
            console.log('startHourList', startHourList)
            var endHourList = moment(currentAvailabilityRulesList[i].to, format)

            if( i != index ) {
                if (startHourNew.isBetween(startHourList, endHourList)) {
                    isSelected = true;
                }
            }
        }

        if(isSelected) {
            Modal.error({
                title: 'This is an error message',
                content: 'This time is included in your previous rules, therefore it cannot be used, please enter a valid time',
            });
        } else {
            currentAvailabilityRulesList[index].from = value.getHours() + ':' + value.getMinutes();
            currentAvailabilityRulesList[index].to = value.getHours() + ':' + value.getMinutes() + 5;
            SetAvailabilityRulesList(currentAvailabilityRulesList);
        }
    };

    const ChangeAvailabilityEndHour = (value, index) => {
        const currentAvailabilityRulesList = [...AvailabilityRulesList];

        var isCorrect = true;
        var startHourNew = moment(currentAvailabilityRulesList[index].from, format);
        var EndHourNew = moment(value.getHours() + ':' + value.getMinutes(), format);
        

        if (EndHourNew.isSameOrBefore(startHourNew, format)) {
            Modal.error({
                title: 'This is an error message',
                content: 'The end time cannot be less than or equal to the start time',
            });
        } else {
            for (let i=0; i < currentAvailabilityRulesList.length; i++) {
                var startHourList = moment(currentAvailabilityRulesList[i].from, format)

                if (startHourNew.isBefore(startHourList, format)) {
                    if ( i != index ) {
                        if (EndHourNew.isAfter(startHourList, format)) {
                            isCorrect = false;
                        }
                    }
                } 
            }

            if (isCorrect) {
                currentAvailabilityRulesList[index].to = value.getHours() + ':' + value.getMinutes();
                SetAvailabilityRulesList(currentAvailabilityRulesList);
            } else {
                Modal.error({
                    title: 'This is an error message',
                    content: 'This time is included in your previous rules, therefore it cannot be used, please enter a valid time',
                });
            }
        }
    };

    const AddHandlerAvailibilityHourForm = () => {
        const currentAvailabilityRulesList = [...AvailabilityRulesList];
        currentAvailabilityRulesList.push({ id: null, from: '0:0', to: '0:0' });
        SetAvailabilityRulesList(currentAvailabilityRulesList);
    };

    const RemoveHandlerAvailibilityHourForm = (index) => {
        const currentAvailabilityRulesList = [...AvailabilityRulesList];
        currentAvailabilityRulesList.splice(index, 1);
        SetAvailabilityRulesList(currentAvailabilityRulesList);
    };

    useEffect(() => {
        const checkboxConfirmation = form.getFieldValue('meta_service_requires_confirmation');

        if (checkboxConfirmation === 1 || checkboxConfirmation === "1") {
            form.setFieldsValue({
                meta_service_requires_confirmation: true,
            });
        } else if (checkboxConfirmation === 0 || checkboxConfirmation === "0") {
            form.setFieldsValue({
                meta_service_requires_confirmation: false,
            });
        }

        const checkboxCancel = form.getFieldValue('meta_service_can_you_cancel');

        if (checkboxCancel === 1 || checkboxCancel === "1") {
            form.setFieldsValue({
                meta_service_can_you_cancel: true,
            });
        } else if (checkboxCancel === 0 || checkboxCancel === "0") {
            form.setFieldsValue({
                meta_service_can_you_cancel: false,
            });
        }
    }, [loadingForm, form]);

    useEffect(() => {
        if (service.id !== undefined) {
            setFieldData();

            //Filling fields of the availability rules
            let currentAvailabilityRulesList = [];
            service.availabilityRules.map((availability_rule) => {
                if (availability_rule.rule_type === 'Range of days') {
                    SetAvailabilityDays({ id: availability_rule.id, days: availability_rule.rule_val_1.split(',')});
                } else if (availability_rule.rule_type === 'Range of hours') {
                    currentAvailabilityRulesList.push({ id: availability_rule.id, from: availability_rule.rule_val_1, to: availability_rule.rule_val_2 });
                }
            });
            if (currentAvailabilityRulesList.length > 0) {
                SetAvailabilityRulesList(currentAvailabilityRulesList);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    // Create the AvailabilityRuleDays
    const createNewAvailabilityRuleDays = async (availability_rule_day) => {
        availability_rule_day.rule_val_1 =
            availability_rule_day.rule_val_1.join();

        availability_rule_day.product_id = service.id;

        try {
            const response = await axiosWithAuth().post(
                `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/create`,
                availability_rule_day
            );

            console.log(response);

            //handleCancel();
            form.resetFields();
        } catch (err) {
            console.log(err.response);

            const modal = Modal.error({
                title: "Error Creating Availability Rule",
                content: err.message,
            });

            setTimeout(() => {
                modal.destroy();
            }, 5000);
        }
    };
    //Update the AvailabilityRuleDays
    const updateAvailabilityRuleDays = async (availability_rule_day, availability_day_id) => {
        availability_rule_day.rule_val_1 =
            availability_rule_day.rule_val_1.join();

        try {
            const response = await axiosWithAuth().put(
                `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/update/${availability_day_id}`,
                availability_rule_day
            );

            console.log(response);

            //handleCancel();
            form.resetFields();
        } catch (err) {
            console.log(err.response);

            const modal = Modal.error({
                title: "Error Updating Availability Rule",
                content: err.message,
            });

            setTimeout(() => {
                modal.destroy();
            }, 5000);
        }
    };

    // Create the AvailabilityRuleHours
    const createNewAvailabilityRuleHours = async (availability_rule_hour) => {
        availability_rule_hour.product_id = service.id;

        try {
            const response = await axiosWithAuth().post(
                `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/create`,
                availability_rule_hour
            );

            console.log(response);

            //handleCancel();
            form.resetFields();
        } catch (err) {
            console.log(err.response);

            const modal = Modal.error({
                title: "Error Creating Availability Rule",
                content: err.message,
            });

            setTimeout(() => {
                modal.destroy();
            }, 5000);
        }
    };
    // Update the AvailabilityRuleHours
    const updateAvailabilityRuleHours = async (availability_rule_hour, availability_hour_id) => {

        try {
            const response = await axiosWithAuth().put(
                `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/update/${availability_hour_id}`,
                availability_rule_hour
            );

            console.log(response);

            //handleCancel();
            form.resetFields();
        } catch (err) {
            console.log(err.response);

            const modal = Modal.error({
                title: "Error Updating Availability Rule",
                content: err.message,
            });

            setTimeout(() => {
                modal.destroy();
            }, 5000);
        }
    };
    // Delete the AvailabilityRuleHours
    const deleteAvailabilityRuleHours = (availability_rule_delete) => {
        const APIURL = `${process.env.REACT_APP_BE_API_URL}/api/appointments/availability/delete/${availability_rule_delete}`;

            axiosWithAuth()
                .delete(APIURL)
                .then((res) => {
                    console.log(res);

                    dispatch(getService(service.id));
                })
                .catch((err) => {
                    console.log(err.response);

                    const modal = Modal.error({
                        title: "Error Deleting Availability Rule",
                        content: err.message,
                    });

                    setTimeout(() => {
                        modal.destroy();
                    }, 5000);
                });
    };

    return (
        <>
            <ContentPageTitle title="Manage Service" icon="pen-to-square" />

            {/* <div className='content-box'> */}
            <Divider />
                {loadingForm ? (
                    <LoadingForm />
                    ) : ( 
                        <div id="services">
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={fields}
                                defaultValue
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                disabled={isSubmitting}
                            >
                                <div className="form-row">
                                    <div className="form-column-left">
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input service's name"
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Service Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="meta_service_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input service's description"
                                                }
                                            ]}
                                        >
                                            <TextArea placeholder="Description" />
                                        </Form.Item>
                                    </div>

                                    <div className="form-column-right">
                                        <Form.Item name='meta_service_logo'>
                                            <UploadMedia
                                                setMedia={(file) =>
                                                    form.setFieldsValue({
                                                        meta_service_logo: file,
                                                    })
                                                }
                                                maxCount={1}
                                                label='Service Logo'
                                                fieldName='meta_service_logo'
                                                form={form}
                                                description='400px * 400px Image - Max Size 10MB'
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <Divider />
                                
                                <div id="service_price_duration" className="form-row">
                                    <div className="service_price_duration_center">
                                        <Tooltip placement="bottom" title={priceText} arrow={mergedArrow}>
                                            <Form.Item
                                                label="Price"
                                                name="meta_service_price"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input service's price"
                                                    }
                                                ]}
                                            >
                                                <InputNumber addonBefore="$" placeholder="1.00" />
                                            </Form.Item>
                                        </Tooltip>
                                    </div>

                                    <div className="service_price_duration_center">
                                        <Form.Item
                                            label="Duration"
                                            name="meta_service_duration_time"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input service's duration"
                                                }
                                            ]}
                                        >
                                            <TimePicker format={'HH:mm'} showNow={false} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div id="service_free" className="form-column">
                                    <Form.Item name="meta_service_free" valuePropName="checked">
                                        <Checkbox>
                                            Free
                                        </Checkbox>
                                    </Form.Item>

                                    <Form.Item name="meta_service_can_you_cancel" valuePropName="checked">
                                        <Checkbox>
                                            Can you cancel?
                                        </Checkbox>
                                    </Form.Item>
                                </div>

                                <Divider />

                                <div id="appointments">
                                    <div className="max-appointments">
                                        <label>Max appointments per block</label>
                                        <Form.Item
                                            name="meta_service_max_appointments"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input maximum appointments per block"
                                                }
                                            ]}
                                        >
                                            <InputNumber placeholder="1" min={1} />
                                        </Form.Item>
                                    </div>

                                    <div className="min-time-appointments">
                                        <label>Min time to make the appointment</label>
                                        <Form.Item
                                            name="meta_service_min_to_make_appointment"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please input the minimum time to make the appointment"
                                                }
                                            ]}
                                        >
                                            <InputNumber placeholder="1" min={0} />
                                        </Form.Item>
                                        <Form.Item
                                            name="meta_service_min_to_make_appointment_period"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select a time period"
                                                }
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 120 }}
                                                placeholder="Select"
                                                options={[
                                                    { value: 'hours', label: 'hours' },
                                                    { value: 'days', label: 'days' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <h2>Availability rules</h2>

                                <div id="availability-rules">
                                    <div className="availability-rules-form">
                                        <div id="range-of-days" style={{width: '100%'}}>
                                            <label>Type: Range of days</label>
                                                <Select
                                                    defaultValue={AvailabilityDays.days}
                                                    mode="multiple"
                                                    showArrow
                                                    placeholder="Select an option"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    allowClear
                                                    onChange={(e) => {
                                                        SetAvailabilityDays({id: AvailabilityDays.id ,days: [...e]});
                                                        //console.log('AvailabilityDays', AvailabilityDays)
                                                    }}
                                                    options={[
                                                        { value: 'sunday', label: 'sunday' },
                                                        { value: 'monday', label: 'monday' },
                                                        { value: 'tuesday', label: 'tuesday' },
                                                        { value: 'wednesday', label: 'wednesday' },
                                                        { value: 'thursday', label: 'thursday' },
                                                        { value: 'friday', label: 'friday' },
                                                        { value: 'saturday', label: 'saturday' },
                                                    ]}
                                                />
                                        </div>
                                    </div>

                                    <Divider />

                                    <div id="range-of-hours" >
                                        <label>Type: Range of hours</label>
                                        {
                                            AvailabilityRulesList.map((dateRange, index) => {
                                                return (
                                                    <div className="availability-rules-hours-row" key={"dateRange" + index}>
                                                        <div className="availability-rules-form">
                                                            <div className="availability-rules-form_from">
                                                                <label>From</label>
                                                                <TimePicker format={TIME_FORMAT} value={moment(dateRange.from, format)} allowClear={false} minuteStep={5} showNow={false} onChange={(e) => {
                                                                    ChangeAvailabilityStartHour(e._d, index);
                                                                }} />
                                                            </div>
                                                            <div className="availability-rules-form_to">
                                                                <label>To</label>
                                                                <TimePicker format={TIME_FORMAT} value={moment(dateRange.to, format)} allowClear={false} minuteStep={5} showNow={false} onChange={(e) => {
                                                                    ChangeAvailabilityEndHour(e._d, index);
                                                                }} />
                                                            </div>
                                                            <div className="deleteContainer">
                                                                {index > 0 && (
                                                                    <div onClick={() => { 
                                                                        console.log('dateRange', dateRange)
                                                                        if (window.confirm("Are you sure you wish to delete this item?")) {
                                                                            RemoveHandlerAvailibilityHourForm(index);
                                                                            if (dateRange.id != null) {
                                                                                SetAvailabilityRulesListDelete([...AvailabilityRulesListDelete, dateRange.id]);
                                                                            }
                                                                        }
                                                                    }} className="deleteButton"><DeleteOutlined /></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>

                                    <Button
                                        id="availability_add"
                                        type='primary'
                                        onClick={AddHandlerAvailibilityHourForm}
                                        className='ant-btn ant-btn-primary'
                                        size='large'
                                    >
                                        +
                                    </Button>
                                </div>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary' loading={isSubmitting}>
                                        {isSubmitting ? "Saving" : "Save Settings"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    )}
            {/* </div> */}
        </>
    );
}
