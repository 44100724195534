//** Import Components */
import React, { useState } from "react";
import { Layout } from "antd";
import DashboardSelectBusiness from "./DashboardSelectBusiness";
import { Link } from "react-router-dom";
import DashboardMenu from "../../../common/DashboardMenu";

//** Import Menu Item */
import { dashboardMenu } from "../data/dashboardMenuItems";

//** Separate antd Layout Components */
const { Sider } = Layout;

export default function DashboardSidebar(props) {
  const { baseURL, collapsed, setCollapsed } = props;

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="dashboard-sidebar"
        width={250}
      >
        <div className="logo">
          <Link to={baseURL}>Business Dashboard</Link>
        </div>

        <DashboardSelectBusiness />

        <DashboardMenu
          baseURL={baseURL}
          dashboardMenu={dashboardMenu}
          mode="inline"
        />
      </Sider>
    </>
  );
}
