//** Import Modules */
import React, { useEffect } from "react";
import { Badge, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBell } from "@fortawesome/free-solid-svg-icons";
import HeaderUserMenu from "./HeaderUserMenu";
import { useSelector, useDispatch } from "react-redux";
import { getNotifications } from "../../features/reducers/notifications";

//** Import Assets */
import logo from "../../images/bizzll-logo-beta.png";
import UserAvatar from "../UserAvatar";
import NotificationItem from "./NotificationItem";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function HeaderUserBar() {
  // Get User Info
  const user = useSelector((state) => state.user.user);

  // Get notifications
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const newNotificationsCount = notifications.filter(
    (item) => item.is_new === 1
  ).length;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  // Function to set notifications to read
  const notificationsOpen = () => {
    if (newNotificationsCount > 0) {
      const APIURL = `/api/users/user/notifications/open`;

      axiosWithAuth()
        .put(APIURL)
        .then((res) => {
          console.log(res);
          dispatch(getNotifications());
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <div id="header-user-bar">
      <Popover
        title="Dashboard Menu"
        trigger="click"
        content={<HeaderUserMenu user={user} />}
        placement="bottomRight"
      >
        <button className="user-avatar">
          <UserAvatar user={user} size={40} single={true} />
        </button>
      </Popover>

      <Popover
        title="Messages"
        trigger="click"
        content={<>Messages</>}
        placement="bottomRight"
        overlayClassName="header-notifications-box"
      >
        <button className="userbar-btns messages-btn">
          <Badge count={0} overflowCount={10} size="small">
            <FontAwesomeIcon icon={faEnvelope} />
          </Badge>
        </button>
      </Popover>

      <Popover
        title="Notifications"
        trigger="click"
        overlayClassName="header-notifications-box"
        content={notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
        placement="bottomRight"
      >
        <button
          className="userbar-btns notifications-btn"
          onClick={notificationsOpen}
        >
          <Badge count={newNotificationsCount} overflowCount={10} size="small">
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </button>
      </Popover>

      <div className="vertical-divider"></div>

      <button className="extra-buttons upgrade-btn">Upgrade</button>
      <a href={`${process.env.REACT_APP_FE_URL}`} className="back-to-bizzll">
        <img src={logo} alt="BIZZLL Logo" />
      </a>
    </div>
  );
}
