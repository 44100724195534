//** Define Actions */
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const SET_RESTAURANTS = "SET_RESTAURANTS";
export const GET_RESTAURANTS_LIST = "GET_RESTAURANTS_LIST";
export const SET_RESTAURANTS_LIST = "SET_RESTAURANTS_LIST";

//** Create action functions */
export const getRestaurant = (restaurantID) => ({
  type: GET_RESTAURANTS,
  restaurantID,
});

export const setRestaurant = (restaurant) => ({
  type: SET_RESTAURANTS,
  restaurant,
});

export const getRestaurantsList = (businessID) => ({
  type: GET_RESTAURANTS_LIST,
  businessID,
});

export const setRestaurantsList = (restaurantsList) => ({
  type: SET_RESTAURANTS_LIST,
  restaurantsList,
});

//** Create initial state */
const initialState = {
  restaurantsList: [],
  restaurant: {},
};

//** Create the handler function */
const restaurantsReducer = (state = initialState, { type, restaurant, restaurantsList }) => {
  switch (type) {
    case SET_RESTAURANTS:
      return {
        ...state,
        restaurant,
      };
    case SET_RESTAURANTS_LIST:
      return {
        ...state,
        restaurantsList: restaurantsList.map((restaurant) => {
          return {
            ...restaurant,
            key: restaurant.id,
          };
        }),
      };
    default:
      return state;
  }
};

export default restaurantsReducer;
