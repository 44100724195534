import React, { useEffect, useState} from "react";
import { useParams } from "react-router";
import ListBackBtn from "../../components/ListBackBtn";
import { useDispatch } from "react-redux";
import { Form, Input, Divider, Select, Button, Checkbox, InputNumber,  message, TimePicker, Modal } from "antd";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

//** Import Components */
import ContentPageTitle from "../../../../common/ContentPageTitle";

export default function AppointmentsByWeekInfo(props) {

    //* Get the appointment Data
    const { id } = useParams();

    const { business } = props;

    useEffect(() => {
    
        const APIURL = `/api/products/business/${business.id}/service`;
    
        axiosWithAuth()
          .get(APIURL)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
    
      }, [business.id]);

    return (
        <>
          <ListBackBtn backURL='/business/appointments' btnTxt='Back To Services List' />
          <ContentPageTitle title="Manage Appointment" icon="pen-to-square" />
          <Divider />
        </>
    );
}
