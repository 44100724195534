import React, { useEffect, useState } from "react";
import { Alert } from "antd";
import ServiceInfo from "./ServiceInfo";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getService } from "../../../../features/reducers/services";
import ListBackBtn from "../../components/ListBackBtn";

export default function ServiceSettings() {
  //* Get the service Data
  const { id } = useParams();

  // Initiate action to get service data
  const dispatch = useDispatch();
  const service = useSelector((state) => state.services.service);

  useEffect(() => {
    dispatch(getService(id));
  }, [dispatch, id]);

  return (
    <>
      <ListBackBtn backURL='/business/services' btnTxt='Back To Services List' />

      <div className='warning-boxes'>
        <Alert
          message='Online Services'
          description={
            <>
              We currently use Zoom to broadcast online services. To start
              broadcasting an event, you will need to start a Zoom meeting. You
              may start a meeting by using the Zoom app, just make sure you use
              your Personal Room(under "New Meeting", make sure "Use My Personal
              Meeting ID" is selected). Alternatively, you may{" "}
              <a
                href='https://zoom.us/meeting#/upcoming'
                target='_blank'
                rel='noreferrer'
              >
                Click Here
              </a>{" "}
              to start a meeting via the web portal(go to "Personal Room", and
              click on "Start")
            </>
          }
          type='warning'
          showIcon
        />
      </div>
      <ServiceInfo service={service} />
    </>
  );
}
