//** Import Modules */
import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { Route, Routes } from "react-router-dom";
import { useMangeManagementItem } from "../../../../hooks/useMangeManagementItem";
import CitySettings from "./CitySettings";
import axiosGeneral from "../../../../utils/axioGenetal";

export default function CitiesManagement(props) {
  const { user } = props;

  //* Get Cities
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const APIURL = `/api/city/all`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        const citiesList = res.data.map((city) => {
          return {
            ...city,
            key: city.id,
          };
        });

        setCities(citiesList);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  //* Prepare Hook
  const hookData = {
    creatorID: user.id,
    setState: setCities,
    itemType: "city",
    deleteItemAPI: "/api/city/delete",
    modalData: {
      title: "Create New City",
      fieldLabel: "City Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useMangeManagementItem(hookData);

  return (
    <div>
      <ContentPageTitle title="Cities Management" icon="city" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have any cities."
                  btnText="Create New City"
                  listItems={cities}
                />

                <CreateItemButton btnText="Create New City" />
              </>
            }
            exact
          />

          <Route path="/:id" element={<CitySettings />} exact />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
