//** Import Modules */
import { Space, Table } from "antd";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { FE_URL, TABLE_DATE_FORMAT } from "../../../../common/constants";
import { getBusinessList } from "../../../../features/reducers/business";
import { useManageBusinessItems } from "../../../../hooks/useManageBusinessItems";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function MyBusinesses({ user }) {
  const businessList = useSelector((state) => state.business.businessList);

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    ownerID: user.id,
    dispatchAction: getBusinessList(),
    itemType: "business",
    modalData: {
      title: "Create New Business",
      fieldLabel: "Business Name",
    },
  };

  const [modalComponent, CreateItemButton] = useManageBusinessItems(hookData);

  // Generate localstorage to know which business you are managing
  const manageBusiness = (id) => {
    localStorage.setItem("editBusiness", id);

    window.location.replace("/business");
  };

  // Delete business, and dispatch action
  const dispatch = useDispatch();

  const deleteBusiness = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      const APIURL = `/api/business/${id}`;

      axiosWithAuth()
        .delete(APIURL)
        .then((res) => {
          dispatch(getBusinessList());
          console.log(res.data, APIURL);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const columns = [
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <>{text.name}</>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(TABLE_DATE_FORMAT)}</>
      ),
    },
    {
      title: "Last Modified",
      key: "modified",
      render: (text) => (
        <>{moment(new Date(text && text.modified)).format(TABLE_DATE_FORMAT)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size='middle'>
          <button
            onClick={() => {
              deleteBusiness(text.id);
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              manageBusiness(`${text.id}`);
            }}
          >
            Manage
          </button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Businesses - BIZZLL Dashboard</title>
      </Helmet>

      {businessList.length > 0 ? (
        <Table columns={columns} dataSource={businessList} />
      ) : (
        <>
          <h2>
            You have no businesses. You may create 1 by clicking the button
            below, or you can search{" "}
            <a href={`${FE_URL}/directory`}>our directory</a> to claim your
            business, if applicable.
          </h2>

          <CreateItemButton btnText='Register Business' />
          {modalComponent}
        </>
      )}
    </>
  );
}
