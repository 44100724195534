//** Import Modules */
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

//** Import Reducers */
import businessReducer from "./reducers/business";
import groupsReducer from "./reducers/groups";
import eventsReducer from "./reducers/events";
import servicesReducer from "./reducers/services";
import restaurantsReducer from "./reducers/restaurants";
import adsReducer from "./reducers/ads";
import userReducer from "./reducers/user";
import reviewsReducer from "./reducers/reviews";
import followersReducer from "./reducers/followers";
import messagesReducer from "./reducers/messages";
import notificationsReducer from "./reducers/notifications";

//** Setup Redux Saga Middleware */
import { watcherSaga } from "./sagas/rootSaga";
const sagaMiddleware = createSagaMiddleware();

//** Combine reducers */
const reducer = combineReducers({
  business: businessReducer,
  user: userReducer,
  events: eventsReducer,
  restaurants: restaurantsReducer,
  services: servicesReducer,
  ads: adsReducer,
  groups: groupsReducer,
  reviews: reviewsReducer,
  followers: followersReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
});

//** Configure store */
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(watcherSaga);
