import React from "react";

export default function ContentFormSubtitle(props) {
  const { title, subtitle } = props;

  return (
    <h4 className='form-subtitle'>
      {title}
      {subtitle !== undefined && <span>{subtitle}</span>}
    </h4>
  );
}
