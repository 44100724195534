import React, { useEffect, useState } from "react";
import { DASHBOARD_URL } from "../../../../common/constants";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import PayoutOnboardingBtn from "../../../../common/StripePayout/PayoutOnboardingBtn";
import PayoutTableDetails from "../../../../common/StripePayout/PayoutTableDetails";

export default function PayoutSection(props) {
  const { user, baseURL } = props;

  const [hasAcc, setHasAcc] = useState(false);

  useEffect(() => {
    if (user.connectAcc.id) {
      setHasAcc(true);
    }
  }, [user]);

  return (
    <div className='payout-section'>
      <ContentFormSubtitle
        title='Payout Settings'
        subtitle='Here you will manage your payout account settings. You MUST have an active payout account to receive commission benefits.'
      />

      {hasAcc ? (
        <PayoutTableDetails
          connectAcc={user.connectAcc}
          objData={user}
          type='user'
          baseURL={baseURL}
        />
      ) : (
        <>
          <h3>Seems like you don't have a payout account.</h3>

          <PayoutOnboardingBtn
            userID={user.id}
            type='user'
            btnTxt='Create Payout Account'
            returnURL={`${DASHBOARD_URL}${baseURL}`}
          />
        </>
      )}
    </div>
  );
}
