import React from "react";

export default function AnalyticsBoxCounter(props) {
  const { color, title, stat } = props;

  return (
    <div className={`analytics-box ${color}`}>
      <div className='title'>{title}</div>

      <div className='stat'>{stat}</div>
    </div>
  );
}
