import React, { useEffect, useState } from "react";
import { Badge, Descriptions } from "antd";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import moment from "moment";
import { DATE_FORMAT } from "../../../common/constants";
import { Helmet } from "react-helmet";

export default function MembershipDetails() {
  const [membershipDetails, setMembershipDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});

  console.log(membershipDetails);

  useEffect(() => {
    const APIURL = `/api/payment/create-customer`;
    axiosWithAuth()
      .post(APIURL)
      .then((res) => {
        console.log("data", res.data);
        setCustomerDetails(res.data.customer);
        setMembershipDetails(res.data.membershipDetails);
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Membership Details - BIZZLL Dashboard</title>
      </Helmet>

      <h2>Main Membership</h2>

      <Descriptions bordered>
        <Descriptions.Item label="Membership" span={3}>
          {membershipDetails.name}
        </Descriptions.Item>
        <Descriptions.Item label="Billing Period">
          {membershipDetails.cycle_period}
        </Descriptions.Item>
        <Descriptions.Item label="Payment">
          ${membershipDetails.cycle_payment}
        </Descriptions.Item>
        <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item>
        <Descriptions.Item label="Date Started">
          {moment(customerDetails.start_date).format(DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Expiration Date">
          {moment(customerDetails.expiration_date).format(DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Badge
            status="processing"
            text={customerDetails.subscription_status}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
