import React from "react";
import ListBackBtn from "../../components/ListBackBtn";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import AnalyticsBoxCounter from "../../../../common/AnalyticsBoxCounter";
import AgeGroups from "./DemographicComponents/AgeGroups";
import GenderGroups from "./DemographicComponents/GenderGroups";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function GroupAnalytics() {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Members",
        data: labels.map(() => 0),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      <ListBackBtn backURL='/business/groups' btnTxt='Back To Groups List' />

      <div className='analytics-container'>
        <div className='main-stats'>
          <AnalyticsBoxCounter title='Members' stat='0' color='purple' />
        </div>

        <div className='graph'>
          <Line data={data} height={120} />
        </div>

        <div className='demographics'>
          <h3>Demographics</h3>

          <AgeGroups />
          <GenderGroups />
        </div>
      </div>
    </>
  );
}
