import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";

export default function NoThread() {
  return (
    <div className='no-thread-selected'>
      <FontAwesomeIcon icon={faCommentDots} />
      <h3>Choose a message thread</h3>
    </div>
  );
}
