//** Import Modules */
import axiosGeneral from "../../../utils/axioGenetal";

export const dashboardGetCities = async (setData) => {
  try {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/city/getAllCities`
    );

    return setData(response.data);
  } catch (err) {
    return err;
  }
};

export const dashboardGetCategories = async (setData) => {
  try {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/business/categories/list`
    );

    return setData(response.data);
  } catch (err) {
    return err.response;
  }
};
