//** Import Modules */
import axiosWithAuth from "../../../utils/axiosWithAuth";

export function requestGetAd(adID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/advertisement/owner/${adID}`,
  });
}

export function requestGetAdList(businessID) {
  return axiosWithAuth().request({
    method: "get",
    url: `${process.env.REACT_APP_BE_API_URL}/api/advertisement/business/${businessID}`,
  });
}
