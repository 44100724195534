//** Import Components */
import { useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import { Form, Input, Modal, Button, message, Table, Empty, Space, Tag } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const useManagementAppointmentsUser = ({
  itemType,
  type,
  creatorID,
  user,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dateFormat = TABLE_DATE_FORMAT;

  //numero de orden - fecha - status - total - acciones (view)

  const columns = [
    {
      title: "ID",
      key: "appointment_id",
      render: (text) => <>{text.id}</>,
    },
    {
      title: "Name",
      key: "ad_name",
      render: (text) => <>{text.service.name}</>,
    },
    {
      title: "Appointment Date",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.start_time)).format(dateFormat)}</>
      ),
    },
    {
      title: "Appointment Start Hour",
      key: "appointment_hour",
      // render: (text) => <> { text && text.start_time.substring(11, 16) } </>
      render: (text) => <> { moment(text.start_time).format('hh:mm A') } </>
      
    },
    {
      title: "Appointment Duration",
      key: "appointment_hour",
      // render: (text) => <> { text && text.service.duration } </>
      render: (text) => <> { console.log('text', text) } </>
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {
            text.status == 'cancelled' &&
            <Tag icon={<ExclamationCircleOutlined />} color="error">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'postponed'  &&
            <Tag icon={<ClockCircleOutlined />} color="warning">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'pending' &&
            <Tag icon={<SyncOutlined spin />} color="processing">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'confirmed' &&
            <Tag icon={<CheckCircleOutlined />} color="success">
              {text && text.status}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
          text.status === 'pending' && 
          <Space size="middle">
            <button
              onClick={() => {
                navigate(`${text.id}`);
              }}
            >
              Manage
            </button>
          </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {
    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={listItems} scroll={{x: 1000 }}/>
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
