//** Import Components */
import { useState } from "react";
import { Table, Empty, Space, Tag, Form, Modal, Button, Select, message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosWithAuth from "../utils/axiosWithAuth";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../common/constants";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

//** Separate antd Select Components */
const { Option } = Select;

export const useManagementAppointments = ({
  itemType,
  type,
  creatorID,
  user,
  modalData,
  // dispatchAction,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statusValue, setStatusValue] = useState({id: '', status: '', startTime: ''});
  const [tableItems, setTableItems] = useState([]);

  //* functions to handle modal
  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const submitModalForm = () => {
    setIsSubmitting(true);

    modalForm.submit();
    window.location.replace("http://localhost:3005/business/appointments");
  };

    //* Function to submit new status
    const onFinish = (value) => {

      const APIURL = `/api/appointments/update/${statusValue.id}`;

      console.log('statusValue', statusValue)

      // const start_time = moment(SelectedTurn.TurnDay + ' ' + SelectedTurn.TurnStartTime).format("YYYY-MM-DD HH:mm:mm");

      const listingData = {
        id: statusValue.id,
        /* start_time: moment(statusValue.startTime).format('dd-mm-yyyy HH:mm:ss'), */
        status: value.status,
      };
  
      axiosWithAuth()
        .put(APIURL, listingData)
        .then(() => {
  
          let config = {
            content: `Status edited!`,
            style: {
              background: "none",
            },
          };
          message.success(config);
  
          closeModal();
          setIsSubmitting(false);
          // dispatch(dispatchAction);
          modalForm.resetFields();
        })
        .catch((err) => {
          console.log(err.response);
          message.error(err.message);
          setIsSubmitting(false);
        });
    };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setIsSubmitting(false);
  };
  
  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "ID",
      key: "appointment_id",
      render: (text) =>
      <>
        {text.id}
      </>,
    },
    {
      title: "Date Created",
      key: "created",
      render: (text) => (
        <>{moment(new Date(text && text.created)).format(dateFormat)}</>
      ),
    },
    {
      title: "Last Modified",
      key: "modified",
      render: (text) => (
        <>{moment(new Date(text && text.modified)).format(dateFormat)}</>
      ),
    },
    {
      title: "Name",
      key: "appointment_name",
      render: (text) => <>{text.service.name}</>,
    },
    {
      title: "Appointment Date",
      key: "appointment_date",
      render: (text) => (
        <>{moment(new Date(text && text.start_time)).format(dateFormat)}</>
      ),
    },
    {
      title: "Start Hour",
      key: "appointment_hour",
      render: (text) => <> { moment(text.start_time).format('hh:mm A') } </>
    },
    {
      title: "Status",
      key: "status",
      render: (text) => (
        <>
          {
            text.status == 'cancelled' &&
            <Tag icon={<ExclamationCircleOutlined />} color="error">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'postponed'  &&
            <Tag icon={<ClockCircleOutlined />} color="warning">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'pending' &&
            <Tag icon={<SyncOutlined spin />} color="processing">
              {text && text.status}
            </Tag>
          }
          {
            text.status == 'confirmed' &&
            <Tag icon={<CheckCircleOutlined />} color="success">
              {text && text.status}
            </Tag>
          }
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text) => (
        <Space size="middle">
          <button
            onClick={() => {
              console.log("Action", text);
              setStatusValue({id: text.id, status: text.status, startTime: text.start_time});
              showModal();
            }}
          >
            Edit Status
          </button>
        </Space>
      ),
    },
  ];

  const ListComponent = ({ emptyMessage, listItems }) => {

    setTableItems(listItems);

    return (
      <>
        {listItems.length > 0 ? (
          <>
            <Table columns={columns} dataSource={tableItems} />
            <Modal
              visible={openModal}
              title="Select Status"
              onCancel={closeModal}
              footer={[
                <Button key="back" onClick={closeModal}>
                  Close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={isSubmitting}
                  onClick={submitModalForm}
                >
                  Submit
                </Button>,
              ]}
            >
              <Form
                form={modalForm}
                layout="vertical"
                name="userForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a option"
                    defaultValue={statusValue.status}
                  >
                    <Option value="confirmed">confirmed</Option>
                    <Option value="pending">pending</Option>
                    <Option value="postponed">postponed</Option>
                    <Option value="cancelled">cancelled</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Modal>
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>{emptyMessage}</span>}
          />
        )}
      </>
    );
  };

  return [ListComponent];
};
