//** Import Modules */
import React, { useEffect } from "react";
import { Button, Form, Divider, Input } from "antd";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../../common/LoadingForm";
import { getGroup } from "../../../../features/reducers/groups";
import ContentPageTitle from "../../../../common/ContentPageTitle";

export default function GroupInfo({ group }) {
  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(group);

  useEffect(() => {
    if (group.id !== undefined) {
      setFieldData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const group_status = [
    {
      key: "public",
      value: "Public",
    },
    {
      key: "private",
      value: "Private",
    },
  ];

  return (
    <>
      <ContentPageTitle title="Group Information" icon="pen-to-square" />

      <Divider />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={fields}
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            submitForm(values, `/api/group/${group.id}`, getGroup(group.id));
          }}
          disabled={isSubmitting}
        >
          <ContentFormSubtitle title="Main Description" />

          <Form.Item name="meta_group_logo">
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_group_logo: file,
                })
              }
              maxCount={1}
              label="Logo"
              fieldName="meta_group_logo"
              form={form}
              description="400px * 400px Image - Max Size 10MB"
            />
          </Form.Item>

          <Form.Item
            label="Group Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your group name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="About My Group" name="description">
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>

          <Divider />

          <ContentFormSubtitle
            title="Page Settings"
            subtitle="These settings are used for the group page"
          />

          <Form.Item name="meta_group_cover">
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_group_cover: file,
                })
              }
              maxCount={1}
              label="Cover Image"
              fieldName="meta_group_cover"
              form={form}
              description="1800px * 400px Image - Max Size 10MB"
              aspectRatio={4.5}
            />
          </Form.Item>

          <Form.Item
            label="Group Handle"
            name="slug"
            tooltip="This is the identifier for your group"
          >
            <Input placeholder="Group Slug" />
          </Form.Item>

          <Form.Item
            label="Group Status"
            name="status"
            tooltip="You can set your group to be either private or public. Private groups can only be accessed by users you invite, and yourself"
            rules={[
              {
                required: true,
                message: "Please choose a status!",
              },
            ]}
          >
            <SelectDropdown
              options={group_status}
              value={form.getFieldValue("status")}
            />
          </Form.Item>

          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
